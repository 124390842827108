import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from '@firebase/auth';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { authInstance } from '../../services/firebase.service';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(authInstance, email, password)
        .catch((error) => Sentry.captureException(error));
      setLoginSuccess(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (loginSuccess) {
    navigate('/');
  }

  return (
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1,
        }}>
            <Box sx={{ p: 4, boxShadow: 2, bgcolor: 'background.paper' }}>
                <form onSubmit={handleSubmit}>
                    <TextField type="text" label={t('email')} variant="outlined" fullWidth margin="normal"
                               data-testid="email"
                               onChange={(e) => setEmail(e.target.value)} />
                    <TextField type="password" label={t('password')} variant="outlined" fullWidth margin="normal"
                               data-testid="password"
                               onChange={(e) => setPassword(e.target.value)} />
                    <Button variant="contained" type="submit" data-testid="loginButton">{t('loginButton')}</Button>
                </form>
            </Box>
        </Box>
  );
};

export default Login;
