import { Timestamp } from 'firebase/firestore';
import i18n from 'i18next';
import { CompletedReport } from '../Types/Types';

class ReportUtils {
  dateToLocaleString(date: Timestamp | null): string | undefined {
    return date?.toDate().toLocaleString(i18n.language || 'en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  }

  weightQuantityToString(weight: number, quantity: number): number {
    if (weight > 0) {
      return weight / 1000;
    }
    return quantity;
  }

  normalizedBarcode(report: CompletedReport): string {
    if (report.barcodeNormalized && report.barcodeNormalized.length > 0) {
      return report.barcodeNormalized;
    }
    return report.barcode;
  }
}

const reportUtils = new ReportUtils();
export default reportUtils;
