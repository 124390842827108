import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import SupplierDrawerStore from '../Drawer/SupplierDrawerStore';
import suppliersTableStore from '../Table/SuppliersTableStore';
import { Supplier, SupplierSearchCriteria } from '../Types/Types';
import SuppliersTable from '../Table/SuppliersTable';
import SupplierDrawer from '../Drawer/SupplierDrawer';
import SupplierService from '../Service/SupplierService';
import SupplierSearch from '../Search/SupplierSearch';

const supplierDashboardDrawerStore = new SupplierDrawerStore();

const SupplierDashboard: React.FC = observer(() => {
  const {
    drawerOpen,
    drawerMode,
    supplier,
    openCreateDrawer,
    closeDrawer,
    setShowValidation,
    isFormValid,
  } = supplierDashboardDrawerStore;
  const { saveSupplier } = suppliersTableStore;
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [showSearchSection, setShowSearchSection] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    SupplierService.getSupplierList().then((result) => {
      // as backend don't support suppliers id's we only operate in name values
      const uniqueSuppliers = result.reduce((unique: Supplier[], item: Supplier) => {
        if (!unique.some((u: Supplier) => u.name === item.name)) {
          unique.push({ ...item, id: item.name });
        }
        return unique;
      }, [] as Supplier[]);
      setSuppliers(uniqueSuppliers);
    });
  }, []);

  const handleSave = async (supplierUpdate: Supplier) => {
    setSavingInProgress(true);
    setShowValidation(true);
    if (isFormValid) {
      await saveSupplier(supplierUpdate);
      closeDrawer();
    }
    setSavingInProgress(false);
  };

  const handleOpenCreateDrawer = () => {
    setSavingInProgress(false);
    openCreateDrawer();
  };

  const toggleSearchSection = () => {
    if (showSearchSection) {
      suppliersTableStore.setSuppliersSearchCriteria({});
      suppliersTableStore.fetchSuppliers('none');
    }
    setShowSearchSection(!showSearchSection);
  };

  const handleSearch = (searchCriteria: SupplierSearchCriteria) => {
    suppliersTableStore.setSuppliersSearchCriteria(searchCriteria);
    suppliersTableStore.fetchSuppliers('none');
  };

  return (
    <div>
      <Box sx={{ marginLeft: 1 }}>
        <h1>{t('suppliers')}</h1>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button onClick={toggleSearchSection}>
            {showSearchSection ? t('hideSearch') : t('showSearch')}
          </Button>
          <Button onClick={handleOpenCreateDrawer} data-testid="addNewSupplierButton">{t('addNewSupplier')}</Button>
        </Box>
      </Box>
      <Box sx={{ marginLeft: 1 }}>
        {showSearchSection && <SupplierSearch onSearch={handleSearch} suppliers={suppliers} />}
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <SuppliersTable />
      </Box>

      <SupplierDrawer
        isOpen={drawerOpen}
        onClose={closeDrawer}
        mode={drawerMode}
        initialSupplier={supplier}
        onSave={handleSave}
        loading={savingInProgress}
        store={supplierDashboardDrawerStore}
      />
    </div>
  );
});

export default SupplierDashboard;
