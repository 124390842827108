import React from 'react';
import { observer } from 'mobx-react-lite';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import completedReportsTableStore from '../Table/CompletedReportsTableStore';
import reportUtils from '../Service/ReportUtils';

const CompletedReportDownload: React.FC = observer(() => {
  const { t } = useTranslation();
  const { completedReports, showMaxReportCountWarning, loading } = completedReportsTableStore;

  const fileNameGenerator = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `palomind_report_${year}_${month}_${day}_${hours}${minutes}${seconds}.xlsx`;
  };

  const handleDownload = () => {
    const reportsForXlsx = completedReportsTableStore.completedReports.map((report) => ({
      [t('documentDate')]: reportUtils.dateToLocaleString(report.reportDate),
      [t('shopId')]: report.shopId,
      [t('documentId')]: report.reportId,
      [t('reportType')]: report.category,
      [t('sap')]: report.sap,
      [t('barcode')]: reportUtils.normalizedBarcode(report),
      [t('name')]: report.productName,
      [t('supplier')]: report.supplier,
      [`${t('weight')}/${t('quantity')}`]: reportUtils.weightQuantityToString(
        report.weight,
        report.quantity,
      ),
    }));

    const ws = XLSX.utils.json_to_sheet(reportsForXlsx);
    ws['!cols'] = [
      { wch: 15 }, // 'Document Date' column
      { wch: 15 }, // 'Shop ID' column
      { wch: 15 }, // 'Document ID' column
      { wch: 15 }, // 'Report Type' column
      { wch: 10 }, // 'SAP' column
      { wch: 15 }, // 'Barcode' column
      { wch: 45 }, // 'Name' column
      { wch: 20 }, // 'Supplier' column
      { wch: 15 }, // 'Weight/Quantity' column
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, t('reports'));
    XLSX.writeFile(wb, fileNameGenerator());
  };

  return (
    <Button
      variant="contained"
      endIcon={<FileDownloadIcon />}
      onClick={handleDownload}
      disabled={completedReports.length === 0 || showMaxReportCountWarning || loading}
    >
      {t('download')}
    </Button>
  );
});

export default CompletedReportDownload;
