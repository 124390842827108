import {
  action, computed, makeObservable, observable,
} from 'mobx';
import * as Types from '../Types/Types';

class UserDrawerStore {
  drawerOpen: boolean = false;

  drawerMode: 'create' | 'edit' = 'create';

  user: Types.User = {
    id: '',
    email: '',
    name: '',
    shops: [],
    reports: [],
  };

  showValidation: boolean = false;

  constructor() {
    makeObservable(this, {
      drawerOpen: observable,
      drawerMode: observable,
      user: observable,
      showValidation: observable,
      openCreateDrawer: action,
      openEditDrawer: action,
      closeDrawer: action,
      isUserIdValid: computed,
      setShowValidation: action,
      isValidationVisible: computed,
      isFormValid: computed,
      setUser: action,
      resetUser: action,
    });
  }

  setUser = (user: Types.User) => {
    this.user.id = user.id;
    this.user.email = user.email;
    this.user.name = user.name;
    this.user.phone = user.phone;
    this.user.shopId = user.shopId;
    this.user.shops = user.shops;
    this.user.reports = user.reports;
    this.user.projectId = user.projectId;
    this.user.admin = user.admin;
  };

  resetUser = () => {
    this.user.id = '';
    this.user.email = '';
    this.user.name = '';
    this.user.phone = '';
    this.user.shopId = '';
    this.user.shops = [];
    this.user.reports = [];
    this.user.projectId = '';
    this.user.admin = false;
  };

  // Drawer related actions
  openCreateDrawer = () => {
    this.drawerMode = 'create';
    this.drawerOpen = true;
    this.showValidation = false;
    this.resetUser();
  };

  openEditDrawer = (user: Types.User) => {
    this.setUser(user);
    this.drawerMode = 'edit';
    this.drawerOpen = true;
  };

  closeDrawer = () => {
    this.drawerOpen = false;
    this.resetUser();
    this.showValidation = false;
  };

  setShowValidation = (showValidation: boolean) => {
    this.showValidation = showValidation;
  };

  get isValidationVisible() {
    return this.showValidation;
  }

  get isFormValid() {
    return this.isUserIdValid;
  }

  get isUserIdValid() {
    return (this.user?.id?.length ?? 0) >= 1;
  }
}

const userDrawerStore = new UserDrawerStore();
export default userDrawerStore;