import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => Promise<void>;
    title: string;
    description: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open, handleClose, handleConfirm, title, description,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleConfirmedAction = async () => {
    setIsLoading(true);
    await handleConfirm();
    setIsLoading(false);
    handleClose();
  };

  return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {isLoading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        <Button onClick={handleConfirmedAction} color="primary" data-testid="confirmButton">
                            {t('confirm')}
                        </Button>
                        <Button onClick={handleClose} color="primary" data-testid="cancelButton">
                            {t('cancel')}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
  );
};

export default ConfirmDialog;
