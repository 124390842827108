import React, { useState } from 'react';
import { Autocomplete, Button, FormControl, Grid, InputLabel, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Product, ProductSearchCriteria } from '../Types/Types';
import './ProductSearch.css';
import { Supplier } from '../../Supplier/Types/Types';
import { supplierOptionLabel } from '../Dashboard/ProductsDashboard';

interface ProductSearchProps {
  onSearch: (searchCriteria: ProductSearchCriteria) => void;
  suppliers: Supplier[];
}

const ProductSearch: React.FC<ProductSearchProps> = (
  {
    onSearch,
    suppliers
  }) => {
  const [searchCriteria, setSearchCriteria] = useState<ProductSearchCriteria>({
    searchBy: 'barcode',
  });
  const { t } = useTranslation();
  const [supplierValue, setSupplierValue] = useState<Supplier | null>(null);

  const handleSearch = () => {
    onSearch(searchCriteria);
  };

  const handleInputChange = (field: keyof Product, value: string) => {
    setSearchCriteria({
      ...searchCriteria,
      [field]: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSupplierValue(null);
    setSearchCriteria({
      ...searchCriteria,
      sap: '',
      supplier: '',
      barcode: '',
      searchBy: event.target.value,
    });
  };

  const handleSupplierChange = (supplier: Supplier | null) => {
    setSupplierValue(supplier);
    setSearchCriteria({
      ...searchCriteria,
      supplier: supplier?.name,
    });
  };

  return (
    <Grid container spacing={1} className="product-search">
      <Grid item xs={1}>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel>{t('searchBy')}</InputLabel>
          <Select
            fullWidth
            value={searchCriteria.searchBy}
            size="small"
            name={'productSearchBy'}
            label={t('searchBy')}
            data-testid="productSearchBySelect"
            onChange={handleSelectChange}
          >
            <MenuItem value="barcode">{t('byBarcode')}</MenuItem>
            <MenuItem value="sap">{t('bySap')}</MenuItem>
            <MenuItem value="supplier">{t('bySupplier')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {searchCriteria.searchBy === 'barcode' && (
        <Grid item xs={2}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              size="small"
              name="barcode"
              label={t('barcode')}
              onChange={(e) => handleInputChange('barcode', e.target.value)}
              defaultValue={searchCriteria.barcode}
            />
          </FormControl>
        </Grid>
      )}
      {searchCriteria.searchBy === 'sap' && (
        <Grid item xs={2}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              size="small"
              name="sap"
              label={t('sap')}
              onChange={(e) => handleInputChange('sap', e.target.value)}
              defaultValue={searchCriteria.sap}
            />
          </FormControl>
        </Grid>
      )}
      {searchCriteria.searchBy === 'supplier' && (
        <Grid item xs={2}>
          <FormControl sx={{ width: '100%' }}>
            <Autocomplete
              options={suppliers}
              size="small"
              getOptionLabel={supplierOptionLabel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('supplier')}
                  variant="outlined"
                />
              )}
              value={supplierValue}
              noOptionsText={t('noOptions')}
              onChange={(event, value) => handleSupplierChange(value)} // Handle selected option
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={2}>
        <Button variant="contained" endIcon={<SearchIcon />} onClick={handleSearch}>
          {t('search')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProductSearch;
