import {
  collection, getDocs, getFirestore, orderBy, query,
} from 'firebase/firestore';
import { Shop } from '../Types/Types';

/**
 * Stateless service class for providing shops data
 */
export default class ShopService {
  public static async getShopList(): Promise<Shop[]> {
    const db = getFirestore();
    const shopsQuery = query(
      collection(db, 'projects/101/shops'),
      orderBy('city', 'asc'),
    );
    const querySnapshot = await getDocs(shopsQuery);
    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  }
}
