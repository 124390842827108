import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button, TableCell, TableRow, TextField } from '@mui/material';
import { Product } from '../Types/Types';
import ConfirmDialog from '../../Common/ConfirmDialog';
import './ProductsTableRow.css';
import ProductDrawer from '../Drawer/ProductDrawer';
import TableActionsCell from '../../Common/Table/TableActionsButtons/TableActionsButtons';
import { tableProductDrawerStore } from './ProductsTable';
import { Supplier } from '../../Supplier/Types/Types';

interface ProductsTableRowProps {
  product: Product;
  onDelete: (productId: string) => Promise<void>;
  onSave: (product: Product) => Promise<void>;
  onEdit: (product: Product) => Promise<void>;
  suppliers: Supplier[];
}

const ProductsTableRow: React.FC<ProductsTableRowProps> = observer(
  ({
    product, onDelete, onSave, onEdit, suppliers
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localProduct, setLocalProduct] = useState<Product>(product);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const { t } = useTranslation();

    // re-populate the local product when the product prop changes (e.g. edit mode)
    useEffect(() => {
      setLocalProduct(product);
    }, [product]);

    const handleInputChange = (field: keyof Product, value: string) => {
      setLocalProduct({
        ...localProduct,
        [field]: value,
      });
    };

    const handleDelete = async () => {
      await onDelete(localProduct.barcode);
      setIsEditing(false);
    };

    const handleCancel = () => {
      setLocalProduct(product); // Revert to the initial state
      setIsEditing(false);
    };

    const handleClose = () => {
      setDialogOpen(false);
    };

    const handleEditDrawerClose = () => {
      setEditDrawerOpen(false);
    };

    const handleEditDrawerSave = async (updatedProduct: Product) => {
      setEditDrawerOpen(false);
      await onSave(updatedProduct);
    };

    const handleEditDrawerOpen = async () => {
      await onEdit(localProduct);
      setEditDrawerOpen(true);
    };

    const handleConfirm = async () => {
      await handleDelete();
      setDialogOpen(false);
    };

    return (
      <>
        <TableRow
          key={localProduct.barcode}
          data-testid="productsTableDataRow"
          className={isEditing ? 'editing' : ''}
        >
          {isEditing ? (
            <>
              <TableCell>{localProduct.barcode}</TableCell>
              <TableCell>
                <TextField
                  defaultValue={localProduct.name}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  defaultValue={localProduct.sap}
                  onChange={(e) => handleInputChange('sap', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  defaultValue={localProduct.supplier}
                  onChange={(e) => handleInputChange('supplier', e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  defaultValue={localProduct.weightCode}
                  onChange={(e) => handleInputChange('weightCode', e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Button onClick={handleCancel}>{t('cancel')}</Button>
              </TableCell>
            </>
          ) : (
            <>
              <TableCell>{localProduct.barcode}</TableCell>
              <TableCell>{localProduct.name}</TableCell>
              <TableCell>{localProduct.sap}</TableCell>
              <TableCell>{localProduct.supplier}</TableCell>
              <TableCell>{localProduct.weightCode}</TableCell>
              <TableActionsCell
                onEdit={handleEditDrawerOpen}
                onDelete={() => setDialogOpen(true)}
              />
            </>
          )}
        </TableRow>
        <ConfirmDialog
          open={dialogOpen}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          title={t('productDeleteConfirmationTitle')}
          description={t('productDeleteConfirmation')}
        />
        <ProductDrawer
          isOpen={editDrawerOpen}
          onClose={handleEditDrawerClose}
          mode="edit"
          loading={false}
          initialProduct={localProduct}
          onSave={handleEditDrawerSave}
          productDrawerStore={tableProductDrawerStore}
          suppliers={suppliers}
        />
      </>
    );
  },
);

export default ProductsTableRow;
