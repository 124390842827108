import React from 'react';
import { Button, ButtonGroup, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TableActionsButtonsProps {
    onEdit: () => void;
    onDelete?: () => void;
}

const TableActionsCell: React.FC<TableActionsButtonsProps> = ({ onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
        <TableCell align="right">
            <ButtonGroup>
                <Button
                    variant="text"
                    color="primary"
                    data-testid="editButton"
                    onClick={onEdit}
                >
                    {t('edit')}
                </Button>
                {onDelete && <Button
                    variant="text"
                    color="secondary"
                    data-testid="deleteButton"
                    sx={{ marginRight: 1 }}
                    onClick={onDelete}
                >
                    {t('delete')}
                </Button>
                }
            </ButtonGroup>
        </TableCell>
  );
};

export default TableActionsCell;
