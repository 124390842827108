import { addDoc, collection, deleteDoc, doc, getFirestore, setDoc } from 'firebase/firestore';
import PageableCollectionStore from './PageableCollectionStore';

/**
 * Store for handling pagination of a collection of entities from Firestore
 */
export default class BasicPageableCollectionStore<T extends { id?: string }> extends PageableCollectionStore<T> {

  protected async saveEntity(entity: T) {
    this.setIsLoading(true);
    const db = getFirestore();

    const newId = entity.id
      ? await setDoc(doc(db, this.config.collectionPath, entity.id), entity)
        .then(() => entity.id)
      : await addDoc(collection(db, this.config.collectionPath), entity)
        .then((docRef) => docRef.id)

    // Update the supplier object with the new ID
    const newEntity = { ...entity, id: newId };
    const existingEntityIndex = this.entities.findIndex(
      (p) => p.id === newId,
    );
    if (existingEntityIndex !== -1) {
      // Supplier exists, update it
      this.entities[existingEntityIndex] = newEntity;
    } else {
      // Supplier doesn't exist, add it
      this.entities = [newEntity, ...this.entities];
    }
    this.setIsLoading(false);
  };

  protected async deleteEntity(id: string) {
    // Remove from Firestore
    const db = getFirestore();
    await deleteDoc(doc(db, this.config.collectionPath, id));

    // Remove from local state (now managed by MobX)
    this.entities = this.entities.filter((entity) => entity.id !== id);
  }

}