import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadMorePagination from '../../Common/LoadMorePagination';
import usersTableStore from './UsersTableStore';
import { User } from '../Types/Types';
import UsersTableRow from './UsersTableRow';
import userDrawerStore from '../Drawer/UserDrawerStore';

const UsersTable: React.FC = observer(() => {
  const { entities, isLoading, hasMorePages } = usersTableStore;
  const { setUser } = userDrawerStore;
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();

  useEffect(() => {
    usersTableStore.fetchUsers('forward');

    return () => {
      // Reset the state here
      usersTableStore.resetState();
    };
  }, []);

  const handleDelete = async (id: string) => {
    await usersTableStore.deleteUser(id);
  };

  const handleSave = async (user: User) => {
    await usersTableStore.saveUser(user);
  };

  const handleEdit = async (user: User) => {
    setUser(user);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
        <>
            <Table>
                <TableHead>
                    <TableRow style={{ backgroundColor: '#f4f4f4' }}>
                        <TableCell style={{ fontWeight: 'bold' }}>{t('email')}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>{t('userName')}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>{t('primaryShop')}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>{t('additionalShops')}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>{t('reportTypes')}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entities.map((user) => (
                        <UsersTableRow
                            key={user.id}
                            user={user}
                            onDelete={handleDelete}
                            onEdit={handleEdit}
                            onSave={handleSave}
                        />
                    ))}
                </TableBody>
            </Table>
            <LoadMorePagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                hasMorePages={hasMorePages}
                isLoading={isLoading}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                fetchProducts={usersTableStore.fetchUsers}
            />
        </>
  );
});

export default UsersTable;
