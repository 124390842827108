import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import imageSrc from './images/palorelax.png';

const Home: React.FC = () => {
  const ImageContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });
  return (
    <Box>
      <Box
        sx={{
          marginTop: '164px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImageContainer>
          <img src={imageSrc} alt="palomind logo" />
        </ImageContainer>
      </Box>
    </Box>
  );
};

export default Home;
