import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';
import CompletedReportsTable from '../Table/CompletedReportsTable';
import CompletedReportsSearch from '../Search/CompletedReportSearch';
import completedReportsTableStore from '../Table/CompletedReportsTableStore';

const CompletedReportDashboard: React.FC = observer(() => {
  const { t } = useTranslation();
  const { showMaxReportCountWarning } = completedReportsTableStore;

  return (
    <div>
      <Box sx={{ marginLeft: 1 }}>
        <h1>{t('reports')}</h1>
      </Box>
      <Box sx={{ marginLeft: 1 }}>
        <CompletedReportsSearch />
      </Box>
      {showMaxReportCountWarning && (
        <Box sx={{ marginTop: 1 }}>
          <Alert severity="warning">{t('maxReportCountWarning')}</Alert>
        </Box>
      )}
      <Box sx={{ marginTop: 1 }}>
        <CompletedReportsTable />
      </Box>
    </div>
  );
});

export default CompletedReportDashboard;
