import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import Box from '@mui/material/Box';
import Header from './Components/Header/Header';
import { authInstance } from './services/firebase.service';
import Login from './Components/Security/Login';
import ProtectedRoute from './Components/Security/ProtectedRoute';
import Home from './Components/Home/Home';
import ProductsDashboard from './Components/Product/Dashboard/ProductsDashboard';
import ShopsDashboard from './Components/Shop/Dashboard/ShopsDashboard';
import UsersDashboard from './Components/User/Dashboard/UsersDashboard';
import ProductImport from './Components/Product/Import/ProductImport';
import SupplierDashboard from './Components/Supplier/Dashboard/SupplierDashboard';
import './monitoring/sentry';
import CompletedReportDashboard from './Components/Report/Dashboard/CompletedReportDashboard';

const App: React.FC = () => {
  const [user] = useAuthState(authInstance);

  return (
    <Router>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <Header isAuthenticated={!!user} />
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute isAuthenticated={!!user} />}>
              <Route path="/" element={<Home />} />
            </Route>
            <Route element={<ProtectedRoute isAuthenticated={!!user} />}>
              <Route path="/users" element={<UsersDashboard />} />
            </Route>
            <Route element={<ProtectedRoute isAuthenticated={!!user} />}>
              <Route path="/reports" element={<CompletedReportDashboard />} />
            </Route>
            <Route element={<ProtectedRoute isAuthenticated={!!user} />}>
              <Route path="/shops" element={<ShopsDashboard />} />
            </Route>
            <Route element={<ProtectedRoute isAuthenticated={!!user} />}>
              <Route path="/products" element={<ProductsDashboard />} />
            </Route>
            <Route element={<ProtectedRoute isAuthenticated={!!user} />}>
              <Route path="/products/import" element={<ProductImport />} />
            </Route>
            <Route element={<ProtectedRoute isAuthenticated={!!user} />}>
              <Route path="/suppliers" element={<SupplierDashboard />} />
            </Route>
          </Routes>
        </Box>
      </Box>
    </Router>
  );
};

export default App;
