import { makeObservable, observable } from 'mobx';
import { collection, deleteDoc, doc, getFirestore, query, setDoc, where } from 'firebase/firestore';
import { PaginationDirection } from '../../Common/Types';
import * as Types from '../Types/Types';
import PageableCollectionStore, { PageableCollectionStoreConfig } from '../../Common/Service/PageableCollectionStore';


const usersPageableCollectionStoreConfig: PageableCollectionStoreConfig<Types.User> = {
  collectionPath: 'users',
  orderByField: 'email',
  orderByDirection: 'asc',
  pageSize: 10,
  convertDocToEntity: (documentData) => ({
    ...documentData.data(),
    id: documentData.id,
  }),
};

class UsersTableStore extends PageableCollectionStore<Types.User> {
  usersSearchCriteria: Types.UserSearchCriteria = {};

  constructor() {
    super(usersPageableCollectionStoreConfig)
    makeObservable(this, {
      usersSearchCriteria: observable,
    });
  }

  fetchUsers = async (direction: PaginationDirection) => {
    const db = getFirestore();

    const searchQuery = (criteria: Types.UserSearchCriteria) => {
      if (criteria.email) {
        return query(
          collection(db, 'users'),
          where('email', '==', this.usersSearchCriteria.email),
        );
      }
      return null;
    }
    return super.fetchEntities(db, direction, searchQuery(this.usersSearchCriteria));
  };

  saveUser = async (user: Types.User) => {
    this.setIsLoading(true);
    const db = getFirestore();
    // user.updatedAt = Timestamp.now();
    await setDoc(
      doc(db, 'users', user.id!),
      {
        id: user.id,
        email: user.email ?? null,
        name: user.name ?? null,
        phone: user.phone ?? null,
        shopId: user.shopId ?? null,
        shops: user.shops ?? null,
        reports: user.reports ?? null,
        projectId: user.projectId ?? null,
        admin: user.admin ?? false,
      },
    );

    const existingProductIndex = this.entities.findIndex((entity) => entity.id === user.id);
    if (existingProductIndex !== -1) {
      // User exists, update it
      this.entities[existingProductIndex] = { ...user };
    } else {
      // User doesn't exist, add it
      this.entities = [{ ...user, id: user.id! }, ...this.entities];
    }
    this.setIsLoading(false);
  };

  async deleteUser(id: string) {
    // Remove from Firestore
    const db = getFirestore();
    await deleteDoc(doc(db, 'users', id));

    // Remove from local state (now managed by MobX)
    this.entities = this.entities.filter((user) => user.id !== id);
  }
}

const usersTableStore = new UsersTableStore();
export default usersTableStore;
