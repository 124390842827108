import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, Chip, FormControl, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import i18n from 'i18next';
import dayjs from 'dayjs';
import { ReportType } from '../Types/Types';
import completedReportsTableStore from '../Table/CompletedReportsTableStore';
import './CompletedReportSearch.css';
import 'dayjs/locale/lt';
import CompletedReportDownload from '../Download/CompletedReportDownload';
import { Shop } from '../../Shop/Types/Types';
import ShopService from '../../Shop/Service/ShopService';
import { Supplier } from '../../Supplier/Types/Types';
import SupplierService from '../../Supplier/Service/SupplierService';
import { supplierOptionLabel } from '../../Product/Dashboard/ProductsDashboard';

const CompletedReportsSearch: React.FC = observer(() => {
  const reportTypes: ReportType[] = [
    {
      id: 'priėmimas',
      name: 'Priėmimas',
    },
    {
      id: 'inventorizacija',
      name: 'Inventorizacija',
    },
    {
      id: 'nurašymai',
      name: 'Nurašymai',
    },
    {
      id: 'maistobankas',
      name: 'Maisto bankas',
    },
    {
      id: 'grąžinimas',
      name: 'Grąžinimas',
    },
    {
      id: 'e-parduotuvė',
      name: 'E-parduotuvė',
    },
    {
      id: 'pervežimas',
      name: 'Pervežimas',
    },
    {
      id: 'kainųspausdinimas',
      name: 'Kainų spausdinimas',
    },
  ];
  const { fetchReports, setCriteria, criteria } = completedReportsTableStore;
  const { t } = useTranslation();
  const [selectedReportTypes, setSelectedReportTypes] = useState<ReportType[]>([]);
  const [selectedReportTypeValue, setSelectedReportTypeValue] = useState<ReportType | null>(null);
  const [selectedReportTypeInputValue, setSelectedReportTypeInputValue] = useState<string>('');
  const [dateAfter, setDateAfter] = useState<dayjs.Dayjs | null>(null);
  const [dateBefore, setDateBefore] = useState<dayjs.Dayjs | null>(null);
  const [shops, setShops] = useState<Shop[]>([]);
  const [loadingShops, setLoadingShops] = useState(false);
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const [selectedShopValue, setSelectedShopValue] = useState<Shop | null>(null);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [supplierValue, setSupplierValue] = useState<Supplier | null>(null);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  useEffect(() => {
    setLoadingShops(true);
    ShopService.getShopList().then((result) => {
      setShops(result);
      setLoadingShops(false);
    });
  }, []);

  useEffect(() => {
    setLoadingSuppliers(true)
    SupplierService.getSupplierList().then((result) => {
      // as backend don't support suppliers id's we only operate in name values
      setLoadingSuppliers(false);
      const uniqueSuppliers = result.reduce((unique: Supplier[], item: Supplier) => {
        if (!unique.some((u: Supplier) => u.name === item.name)) {
          unique.push({ ...item, id: item.name });
        }
        return unique;
      }, [] as Supplier[]);
      setSuppliers(uniqueSuppliers);
    });
  }, []);

  const withEmptyOptionLabel = (option: ReportType) => {
    if (option.id === 'empty') {
      return '';
    }
    return `${option.name}`;
  };

  const withEmptyShopOptionLabel = (option: Shop) => {
    if (option.id === 'empty') {
      return '';
    }
    return `${option.city} - ${option.name} (${option.id})`;
  };

  const handleSearch = async () => {
    setCriteria({
      ...criteria,
      reportTypes: selectedReportTypes.map((elem) => elem.id),
      reportDateAfter: dateAfter?.toDate(),
      reportDateBefore: dateBefore?.toDate(),
      shopId: selectedShop?.id,
      supplier: supplierValue?.name
    });
    await fetchReports();
  };

  const handleReportTypeChange = (reportType: ReportType | null) => {
    setSelectedReportTypeInputValue('');
    if (!reportType) {
      return;
    }
    const newShops = [...selectedReportTypes, reportType];
    setSelectedReportTypes(newShops);
    setSelectedReportTypeValue(null);
  };

  const handleReportTypeRemove = (reportType: ReportType) => {
    setSelectedReportTypes(selectedReportTypes.filter((element) => element.id !== reportType.id));
  };

  const handleShopChange = (shop: Shop | null) => {
    setSelectedShop(shop);
    setSelectedShopValue(shop);
  };

  const handleSupplierChange = (supplier: Supplier | null) => {
    setSupplierValue(supplier);
  }

  return (
    <Grid container spacing={1} className="completedReports-search">
      <Grid item xs={1}>
        <Autocomplete
          options={reportTypes.filter((reportType) => !selectedReportTypes.includes(reportType))}
          size={'small'}
          value={selectedReportTypeValue}
          getOptionLabel={withEmptyOptionLabel}
          renderInput={(params) => (
            <TextField {...params} label={t('reportTypes')} variant="outlined" />
          )}
          inputValue={selectedReportTypeInputValue}
          noOptionsText={t('noOptions')}
          onChange={(event, value) => handleReportTypeChange(value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          options={shops}
          size={'small'}
          getOptionLabel={(option) => withEmptyShopOptionLabel(option)}
          loading={loadingShops}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('shopId')}
              variant="outlined"
            />
          )}
          value={selectedShopValue}
          noOptionsText={t('noOptions')}
          onChange={(event, value) => handleShopChange(value)} // Handle selected option
        />
      </Grid>
      <Grid item xs={2}>
          <Autocomplete
            options={suppliers}
            size={'small'}
            getOptionLabel={supplierOptionLabel}
            loading={loadingSuppliers}
            data-testid="suppliersOptions"
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('supplier')}
                data-testid="supplierInput"
                variant="outlined"
              />
            )}
            value={supplierValue}
            noOptionsText={t('noOptions')}
            onChange={(event, value) => handleSupplierChange(value)} // Handle selected option
          />
      </Grid>
      <Grid item xs={11}>
        {selectedReportTypes.map((reportType) => (
          <Chip
            className="CompletedReportsSearch-chip"
            key={reportType.id}
            label={`${reportType.name}`}
            onDelete={() => handleReportTypeRemove(reportType)}
          />
        ))}
      </Grid>
      <Grid item xs={2}>
        <FormControl sx={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <DateTimePicker
              slotProps={{ textField: { size: 'small' } }}
              label={t('dateAfter')}
              value={dateAfter}
              onChange={setDateAfter}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl sx={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <DateTimePicker
              slotProps={{ textField: { size: 'small' } }}
              label={t('dateBefore')}
              value={dateBefore}
              onChange={setDateBefore}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={8}></Grid>
      <Grid item xs={1}>
        <FormControl sx={{ width: '100%' }}>
          <Button variant="contained" endIcon={<SearchIcon />} onClick={handleSearch}>
            {t('search')}
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl sx={{ width: '100%' }}>
          <CompletedReportDownload />
        </FormControl>
      </Grid>
    </Grid>
  );
});

export default CompletedReportsSearch;
