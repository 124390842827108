import { makeObservable, observable } from 'mobx';
import { collection, deleteDoc, doc, getFirestore, query, setDoc, Timestamp, where } from 'firebase/firestore';
import * as Types from '../Types/Types';
import { PaginationDirection } from '../../Common/Types';
import ProductService from '../Service/ProductService';
import PageableCollectionStore, { PageableCollectionStoreConfig } from '../../Common/Service/PageableCollectionStore';

const productsPageableCollectionStoreConfig: PageableCollectionStoreConfig<Types.Product> = {
  collectionPath: 'projects/101/products',
  orderByField: 'updatedAt',
  orderByDirection: 'desc',
  pageSize: 10,
  convertDocToEntity: (documentData) => ({
    ...documentData.data(),
    barcode: documentData.id,
  })
};

class ProductsTableStore extends PageableCollectionStore<Types.Product> {

  productsSearchCriteria: Types.ProductSearchCriteria = {};

  constructor() {
    super(productsPageableCollectionStoreConfig)
    makeObservable(this, {
      productsSearchCriteria: observable,
    });
  }

  setProductsSearchCriteria = (
    productsSearchCriteria: Types.ProductSearchCriteria,
  ) => {
    this.productsSearchCriteria = productsSearchCriteria;
  };

  fetchProducts = async (direction: PaginationDirection) => {
    const db = getFirestore();

    const searchQuery = (criteria: Types.ProductSearchCriteria) => {
      if (criteria.searchBy === 'barcode' && criteria.barcode) {
        return query(
          collection(db, 'projects/101/products'),
          where('barcode', '==', criteria.barcode),
        )
      }
      if (criteria.searchBy === 'sap' && criteria.sap) {
        return query(
          collection(db, 'projects/101/products'),
          where('sap', '==', criteria.sap),
        )
      }
      if (criteria.searchBy === 'supplier' && criteria.supplier) {
        return query(
          collection(db, 'projects/101/products'),
          where('supplier', '==', criteria.supplier),
        )
      }
      return null;
    }

    return super.fetchEntities(db, direction, searchQuery(this.productsSearchCriteria));
  };

  saveProduct = async (product: Types.Product) => {
    this.setIsLoading(true);
    const db = getFirestore();
    const productData = { ...product,
      updatedAt: Timestamp.now(),
    };
    await setDoc(doc(db, 'projects/101/products', productData.barcode!), productData);

    const existingProductIndex = this.entities.findIndex(
      (p) => p.barcode === product.barcode,
    );
    if (existingProductIndex !== -1) {
      // Product exists, update it
      this.entities[existingProductIndex] = { ...productData };
    } else {
      // Product doesn't exist, add it
      this.entities = [
        { ...productData, barcode: productData.barcode! },
        ...this.entities,
      ];
    }
    await ProductService.updateProductFullInfo(productData);
    await ProductService.updateProductBasicInfo(productData);
    await ProductService.updateProductCacheMetadata(productData);
    this.setIsLoading(false);
  };

  async deleteProduct(id: string) {
    // Remove from Firestore
    const db = getFirestore();
    await deleteDoc(doc(db, 'projects/101/products', id));

    // Remove from local state (now managed by MobX)
    this.entities = this.entities.filter((product) => product.barcode !== id);
  }
}

const productsTableStore = new ProductsTableStore();
export default productsTableStore;