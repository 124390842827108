import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import completedReportsTableStore from './CompletedReportsTableStore';
import reportUtils from '../Service/ReportUtils';

const CompletedReportsTable: React.FC = observer(() => {
  const { completedReports, loading } = completedReportsTableStore;
  const { t } = useTranslation();

  useEffect(() => {
    completedReportsTableStore.fetchReports();

    return () => {
      completedReportsTableStore.resetState();
    };
  }, []);

  return (
    <>
      <Table data-testid="completedReportsTable">
        <TableHead>
          <TableRow style={{ backgroundColor: '#f4f4f4' }}>
            <TableCell style={{ fontWeight: 'bold' }}>{t('documentDate')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('shopId')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('documentId')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('reportType')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('sap')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('barcode')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('name')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('supplier')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('weight')}/{t('quantity')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? // Display 10 Skeleton rows when loading
              Array.from(new Array(10)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
            : completedReports.map((report) => (
                <TableRow key={report.id} data-testid="completedReportTableDataRow">
                  <TableCell>{reportUtils.dateToLocaleString(report.reportDate)}</TableCell>
                  <TableCell>{report.shopId}</TableCell>
                  <TableCell>{report.reportId}</TableCell>
                  <TableCell>{report.category}</TableCell>
                  <TableCell>{report.sap}</TableCell>
                  <TableCell>{reportUtils.normalizedBarcode(report)}</TableCell>
                  <TableCell>{report.productName}</TableCell>
                  <TableCell>{report.supplier}</TableCell>
                  <TableCell>
                    {reportUtils.weightQuantityToString(report.weight, report.quantity)}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </>
  );
});

export default CompletedReportsTable;
