import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Chip, Drawer, TextField, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { User } from '../Types/Types';
import userDrawerStore from './UserDrawerStore';
import { Shop } from '../../Shop/Types/Types';
import ShopService from '../../Shop/Service/ShopService';
import LoadingButtonContent from '../../Common/Drawer/LoadingButtonContent/LoadingButtonContent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (user: User) => void;
  mode: 'create' | 'edit';
  initialUser?: User | null; // only needed for edit
  loading: boolean;
}

const UserDrawer: React.FC<Props> = observer(
  ({
    isOpen, onClose, onSave, mode, initialUser, loading,
  }) => {
    const { user, setUser, resetUser } = userDrawerStore;
    const { t } = useTranslation();
    const [loadingShops, setLoadingShops] = useState(false);
    const [shops, setShops] = useState<Shop[]>([]);
    const [selectedAdditionalShops, setSelectedAdditionalShops] = useState<
      Shop[]
    >(
      initialUser?.shops?.map((shopId) => ({
        id: shopId,
        city: '',
        name: '',
      })) || [],
    );
    const [shopValue, setShopValue] = useState<Shop | null>(null);
    const [additionalShopInputValue, setAdditionalShopInputValue] = useState<string>('');
    const [additionalShopValue, setAdditionalShopValue] = useState<Shop | null>(
      null,
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const drawerWidth = isMobile ? '80%' : 600; // Full width for mobile, 600px for larger screens

    // Populate the form when in 'edit' mode
    useEffect(() => {
      if (mode === 'edit' && initialUser) {
        setUser(initialUser);
      } else {
        // Reset the form when in 'create' mode
        resetUser();
      }
      setLoadingShops(true);
      ShopService.getShopList().then((result) => {
        setShops(result);
        setSelectedAdditionalShops(
          selectedAdditionalShops.map((shop) => {
            const shopInfo = result.find((s) => s.id === shop.id);
            return {
              ...shop,
              city: shopInfo ? shopInfo.city : '',
              name: shopInfo ? shopInfo.name : '',
            };
          }),
        );
        setShopValue(
          result.find((shop) => shop.id === initialUser?.shopId) || null,
        );
        setLoadingShops(false);
      });
    }, [mode, initialUser]);

    const withEmptyShopOptionLabel = (option: Shop) => {
      if (option.id === 'empty') {
        return '';
      }
      return `${option.city} - ${option.name} (${option.id})`;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setUser({
        ...user,
        [name]: value,
      });
    };

    const handleReportsChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const newReports = event.target.value
        .split(',')
        .map((report) => report.trim());
      setUser({
        ...user,
        reports: newReports,
      });
    };

    const handlePrimaryShopChange = (shop: Shop | null) => {
      setUser({
        ...user,
        shopId: shop?.id,
      });
    };

    const handleAdditionalShopsChange = (shop: Shop | null) => {
      setAdditionalShopInputValue('');
      if (!shop) {
        return;
      }
      const newShops = [...selectedAdditionalShops, shop];
      setSelectedAdditionalShops(newShops);
      setUser({
        ...user,
        shops: newShops.map((shopData) => shopData.id),
      });
      setAdditionalShopValue(null);
    };

    const handleAdditionalShopRemove = (shop: Shop) => {
      setSelectedAdditionalShops(
        selectedAdditionalShops.filter((s) => s.id !== shop.id),
      );
      setUser({
        ...user,
        shops: selectedAdditionalShops
          .filter((s) => s.id !== shop.id)
          .map((shopData) => shopData.id),
      });
    };

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      onSave(user);
    };

    return (
      <Drawer anchor="right" open={isOpen} onClose={onClose}>
        <Box p={3} width={drawerWidth}>
          <Typography variant="h6" gutterBottom>
            {t('addUser')}
          </Typography>
          <Box mt={2}>
            <form onSubmit={handleSubmit}>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="email"
                  label={t('email')}
                  value={user.email}
                  disabled={mode === 'edit'}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="name"
                  label={t('name')}
                  value={user.name}
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="reports"
                  label={t('reportTypes')}
                  value={user.reports?.join(', ') || ''}
                  onChange={handleReportsChange}
                />
              </Box>
              <Box mb={2}>
                <Autocomplete
                  options={shops}
                  getOptionLabel={(option) => withEmptyShopOptionLabel(option)}
                  loading={loadingShops}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('primaryShop')}
                      variant="outlined"
                    />
                  )}
                  value={shopValue}
                  noOptionsText={t('noOptions')}
                  onChange={(event, value) => handlePrimaryShopChange(value)} // Handle selected option
                />
              </Box>
              <Box mb={2}>
                <Autocomplete
                  options={shops.filter(
                    (shop) => !selectedAdditionalShops
                      .map((selectedShop) => selectedShop.id)
                      .includes(shop.id),
                  )}
                  value={additionalShopValue}
                  getOptionLabel={withEmptyShopOptionLabel}
                  loading={loadingShops}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('addAdditionalShop')}
                      variant="outlined"
                    />
                  )}
                  inputValue={additionalShopInputValue} // Controlled input value
                  noOptionsText={t('noOptions')}
                  onChange={(event, value) => handleAdditionalShopsChange(value)
                  }
                />
              </Box>
              <Box mb={2} sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {selectedAdditionalShops.map((shop) => (
                  <Chip
                    label={`${shop.city} - ${shop.name} (${shop.id})`}
                    onDelete={() => handleAdditionalShopRemove(shop)}
                  />
                ))}
              </Box>

              <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  <LoadingButtonContent loading={loading} mode={mode} />
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('cancel')}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Drawer>
    );
  },
);

export default UserDrawer;
