import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import UsersTable from '../Table/UsersTable';
import userDrawerStore from '../Drawer/UserDrawerStore';
import { User } from '../Types/Types';
import usersTableStore from '../Table/UsersTableStore';
import UserDrawer from '../Drawer/UserDrawer';

const UsersDashboard: React.FC = observer(() => {
  const {
    drawerOpen,
    drawerMode,
    user,
    closeDrawer,
    setShowValidation,
    isFormValid,
  } = userDrawerStore;
  const { saveUser } = usersTableStore;

  const [savingInProgress, setSavingInProgress] = useState(false);
  const { t } = useTranslation();

  const handleSave = async (userData: User) => {
    setSavingInProgress(true);
    setShowValidation(true);
    if (isFormValid) {
      await saveUser(userData);
      // closeDrawer();
    }
    setSavingInProgress(false);
  };

  return (
        <div>
            <Box sx={{ marginLeft: 1 }}>
                <h1>{t('users')}</h1>
            </Box>
            {/* <Button onClick={handleOpenCreateDrawer}>{t("addNewUser")}</Button> */}
            <Box sx={{ marginTop: 1 }}>
                <UsersTable/>
            </Box>

            <UserDrawer
                isOpen={drawerOpen}
                onClose={closeDrawer}
                mode={drawerMode}
                initialUser={user}
                onSave={handleSave}
                loading={savingInProgress}
            />
        </div>
  );
});

export default UsersDashboard;
