import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Box, Button, Drawer, FormHelperText, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import SupplierDrawerStore from './SupplierDrawerStore';
import { Supplier } from '../Types/Types';
import LoadingButtonContent from '../../Common/Drawer/LoadingButtonContent/LoadingButtonContent';

interface SupplierDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (supplier: Supplier) => void;
    mode: 'create' | 'edit';
    initialSupplier?: Supplier | null; // only needed for edit
    loading: boolean;
    store: SupplierDrawerStore;
}

const SupplierDrawer: React.FC<SupplierDrawerProps> = observer((
  {
    isOpen,
    onClose,
    onSave,
    mode,
    initialSupplier,
    loading,
    store,
  },
) => {
  const {
    supplier,
    setSupplier,
    resetSupplier,
    showValidation,
    isSupplierNameValid,
  } = store;
  const { t } = useTranslation();

  // Populate the form when in 'edit' mode
  useEffect(() => {
    if (mode === 'edit' && initialSupplier) {
      setSupplier(initialSupplier);
    } else {
      // Reset the form when in 'create' mode
      resetSupplier();
    }
  }, [mode, initialSupplier]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSupplier({
      ...supplier,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(supplier);
  };

  return (
            <Drawer anchor="right" open={isOpen} onClose={onClose}>
                <Box p={3} width={600}>
                    <Typography variant="h6" gutterBottom>
                        {mode === 'create' ? t('addSupplier') : t('editSupplier')}
                    </Typography>
                    <Box mt={2}>
                        <form onSubmit={handleSubmit}>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    name="name"
                                    data-testid="nameInput"
                                    label={t('name')}
                                    value={supplier.name}
                                    onChange={handleChange}
                                    error={showValidation && !isSupplierNameValid}
                                />
                                {showValidation && !isSupplierNameValid && (
                                    <FormHelperText error data-testid="nameValidationError">{t('fieldIsRequired')}</FormHelperText>
                                )}
                            </Box>
                            <Box mt={3} display="flex" justifyContent="space-between">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    data-testid="supplierSaveButton"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                  <LoadingButtonContent loading={loading} mode={mode} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    data-testid="supplierCancelButton"
                                    onClick={() => {
                                      onClose();
                                    }}
                                >
                                    {t('cancel')}
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Drawer>
  );
});

export default SupplierDrawer;
