import {
  action, computed, makeObservable, observable,
} from 'mobx';
import * as Types from '../Types/Types';

export default class ProductDrawerStore {
  drawerOpen: boolean = false;

  drawerMode: 'create' | 'edit' = 'create';

  product: Types.Product = {
    barcode: '',
    name: '',
    sap: '',
    supplier: '',
    weightCode: '',
    returnablePackageCd: '',
  };

  showValidation: boolean = false;

  initReturnablePackageCd: string | null = null; // used to track required sync for supplement product table (part of import product flow)

  constructor() {
    makeObservable(this, {
      drawerOpen: observable,
      drawerMode: observable,
      product: observable,
      showValidation: observable,
      openCreateDrawer: action,
      openEditDrawer: action,
      closeDrawer: action,
      isBarcodeValid: computed,
      setShowValidation: action,
      isValidationVisible: computed,
      isFormValid: computed,
      setProduct: action,
      resetProduct: action,
    });
  }

  setProduct = (product: Types.Product) => {
    this.product.barcode = product.barcode;
    this.product.name = product.name;
    this.product.sap = product.sap;
    this.product.supplier = product.supplier;
    this.product.weightCode = product.weightCode;
    this.product.returnablePackageCd = product.returnablePackageCd ?? null;
    if (this.product.returnablePackageCd === "empty") {
      this.product.returnablePackageCd = null;
    }
  };

  resetProduct = () => {
    this.product.barcode = '';
    this.product.name = '';
    this.product.sap = '';
    this.product.supplier = '';
    this.product.weightCode = '';
    this.product.returnablePackageCd = null;
  };

  // Drawer related actions
  openCreateDrawer = () => {
    this.drawerMode = 'create';
    this.drawerOpen = true;
    this.showValidation = false;
    this.resetProduct();
  };

  openEditDrawer = (product: Types.Product) => {
    this.setProduct(product);
    this.drawerMode = 'edit';
    this.drawerOpen = true;
  };

  closeDrawer = () => {
    this.drawerOpen = false;
    this.resetProduct();
    this.showValidation = false;
  };

  setInitReturnablePackageCd = (initReturnablePackageCd: string|undefined|null) => {
    this.initReturnablePackageCd = initReturnablePackageCd ?? null;
  };

  setShowValidation = (showValidation: boolean) => {
    this.showValidation = showValidation;
  };

  get isValidationVisible() {
    return this.showValidation;
  }

  get isFormValid() {
    return this.isBarcodeValid;
  }

  get isBarcodeValid() {
    return (this.product?.barcode?.length ?? 0) >= 1;
  }
}
