import { makeAutoObservable } from 'mobx';
import {
  collection, getDocs, getFirestore, query,
} from 'firebase/firestore';
import { ProductSupplement } from '../Types/Types';

class ProductSupplementStore {
  loading: boolean = false;

  supplements: ProductSupplement[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setSupplements = (supplements: ProductSupplement[]) => {
    this.supplements = supplements;
  };

  resetState = () => {
    this.loading = false;
    this.supplements = [];
  };

  fetchProductSupplements = async () => {
    this.setLoading(true);
    const db = getFirestore();
    const supplementsQuery = query(collection(db, 'projects/101/supplementProducts'));
    const querySnapshot = await getDocs(supplementsQuery);
    const newProductSupplements = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
    }));
    this.setSupplements(newProductSupplements);
    this.setLoading(false);
  };
}

const productSupplementStore = new ProductSupplementStore();
export default productSupplementStore;