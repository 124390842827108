import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from '@mui/material';
import { Supplier } from '../Types/Types';
import TableActionsCell from '../../Common/Table/TableActionsButtons/TableActionsButtons';
import ConfirmDialog from '../../Common/ConfirmDialog';
import SupplierDrawer from "../Drawer/SupplierDrawer";
import { supplierTableDrawerStore } from './SuppliersTable';

interface SuppliersTableRowProps {
    supplier: Supplier;
    onDelete: (supplierId: string) => Promise<void>;
    onSave: (supplier: Supplier) => Promise<void>;
    onEdit: (supplier: Supplier) => Promise<void>;
}

const SuppliersTableRow: React.FC<SuppliersTableRowProps> = observer(
  ({
    supplier, onDelete, onSave, onEdit,
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localSupplier, setLocalSupplier] = useState<Supplier>(supplier);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [editInProgress, setEditInProgress] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      setLocalSupplier(supplier);
    }, [supplier]);

    const handleEditDrawerOpen = async () => {
      await onEdit(localSupplier);
      setEditDrawerOpen(true);
    };

    const handleClose = () => {
      setDialogOpen(false);
    };

    const handleEditDrawerClose = () => {
      setEditDrawerOpen(false);
    };

    const handleEditDrawerSave = async (updatedSupplier: Supplier) => {
      setEditInProgress(true);
      await onSave(updatedSupplier);
      setEditInProgress(false);
      setEditDrawerOpen(false);
    };

    const handleConfirm = async () => {
      await onDelete(localSupplier.id!);
      setIsEditing(false);
      setDialogOpen(false);
    };

    return (
            <>
                <TableRow data-testid="suppliersTableDataRow"
                          key={localSupplier.id} className={isEditing ? 'editing' : ''}>
                    <>
                        <TableCell>{localSupplier.name}</TableCell>
                        <TableActionsCell
                            onEdit={handleEditDrawerOpen}
                            onDelete={() => setDialogOpen(true)}
                        />
                    </>
                </TableRow>
                <ConfirmDialog
                    open={dialogOpen}
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                    title={t('supplierDeleteConfirmationTitle')}
                    description={t('supplierDeleteConfirmation')}
                />
                <SupplierDrawer
                    isOpen={editDrawerOpen}
                    onClose={handleEditDrawerClose}
                    mode="edit"
                    loading={editInProgress}
                    initialSupplier={localSupplier}
                    onSave={handleEditDrawerSave}
                    store={supplierTableDrawerStore}
                />
            </>
    );
  },
);

export default SuppliersTableRow;
