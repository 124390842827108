import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ProductDrawerStore from './ProductDrawerStore';
import { Product } from '../Types/Types';
import { Supplier } from '../../Supplier/Types/Types';
import { supplierOptionLabel } from '../Dashboard/ProductsDashboard';

interface ProductDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (product: Product) => void;
  mode: 'create' | 'edit';
  initialProduct?: Product | null; // only needed for edit
  loading: boolean;
  productDrawerStore: ProductDrawerStore;
  suppliers: Supplier[];
}

const ProductDrawer: React.FC<ProductDrawerProps> = observer(
  ({ isOpen, onClose, onSave, mode, initialProduct, loading, productDrawerStore, suppliers }) => {
    const {
      product,
      setProduct,
      setInitReturnablePackageCd,
      resetProduct,
      showValidation,
      isBarcodeValid,
    } = productDrawerStore;
    const { t } = useTranslation();
    const [supplierValue, setSupplierValue] = useState<Supplier | null>(null);
    const [loadingSuppliers, setLoadingSuppliers] = useState(false);

    // Populate the form when in 'edit' mode
    useEffect(() => {
      if (mode === 'edit' && initialProduct) {
        setProduct(initialProduct);
        setInitReturnablePackageCd(initialProduct.returnablePackageCd);
      } else {
        // Reset the form when in 'create' mode
        resetProduct();
      }
    }, [mode, initialProduct]);

    useEffect(() => {
      setLoadingSuppliers(true);
      setSupplierValue(
        suppliers.find((supplier) => supplier.name === initialProduct?.supplier) || null,
      );
      setLoadingSuppliers(false);
    }, [suppliers]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setProduct({
        ...product,
        [name]: value,
      });
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
      setProduct({
        ...product,
        returnablePackageCd: event.target.value ?? null,
      });
    };

    const handleSupplierChange = (supplier: Supplier | null) => {
      setSupplierValue(supplier);
      setProduct({
        ...product,
        supplier: supplier?.name,
      });
    };

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      onSave(product);
    };

    return (
      <Drawer anchor="right" open={isOpen} onClose={onClose}>
        <Box p={3} width={600}>
          <Typography variant="h6" gutterBottom>
            {mode === 'create' ? t('addProduct') : t('editProduct')}
          </Typography>
          <Box mt={2}>
            <form onSubmit={handleSubmit}>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="barcode"
                  label={t('barcode')}
                  value={product.barcode}
                  disabled={mode === 'edit'}
                  data-testid="barcodeInput"
                  onChange={handleChange}
                  error={showValidation && !isBarcodeValid}
                />
                {showValidation && !isBarcodeValid && (
                  <FormHelperText error data-testid="barcodeValidationError">
                    {t('barcodeIsRequired')}
                  </FormHelperText>
                )}
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="name"
                  label={t('name')}
                  data-testid="nameInput"
                  value={product.name}
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="sap"
                  label={t('sap')}
                  data-testid="sapInput"
                  value={product.sap}
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <Autocomplete
                  options={suppliers}
                  getOptionLabel={supplierOptionLabel}
                  loading={loadingSuppliers}
                  data-testid="suppliersOptions"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('supplier')}
                      data-testid="supplierInput"
                      variant="outlined"
                    />
                  )}
                  value={supplierValue}
                  noOptionsText={t('noOptions')}
                  onChange={(event, value) => handleSupplierChange(value)} // Handle selected option
                />
              </Box>
              <Box mb={2}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {t('returnablePackage')}
                  </InputLabel>
                  <Select
                    fullWidth
                    value={product.returnablePackageCd ?? 'empty'}
                    name={'returnablePackageCd'}
                    label={t('returnablePackage')}
                    data-testid="returnablePackageInput"
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="empty">{t('noContainer')}</MenuItem>
                    <MenuItem value="590">{t('glassContainer')}</MenuItem>
                    <MenuItem value="591">{t('plasticContainer')}</MenuItem>
                    <MenuItem value="700">{t('metalContainer')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  data-testid="productSaveButton"
                  disabled={loading}
                >
                  {loading && <CircularProgress size={24} />}
                  {loading && mode === 'create' ? t('add') : t('save')}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  data-testid="productDrawerCloseButton"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('cancel')}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Drawer>
    );
  },
);

export default ProductDrawer;
