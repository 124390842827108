import { DocumentData, Firestore, Query, QueryDocumentSnapshot } from '@firebase/firestore';
import { action, makeObservable, observable } from 'mobx';
import { collection, endBefore, getDocs, limit, limitToLast, orderBy, query, startAfter } from 'firebase/firestore';
import { PaginationDirection } from '../Types';

/**
 * Configuration for the PageableCollectionStore
 */
export interface PageableCollectionStoreConfig<T> {
  collectionPath: string;
  orderByField: string;
  orderByDirection: 'asc' | 'desc';
  pageSize: number;
  convertDocToEntity: (documentData: DocumentData) => T;
}

/**
 * Store for handling pagination of a collection of entities from Firestore
 */
export default class PageableCollectionStore<T> {

  // List of entities in the current page
  public entities: T[] = [];

  // Loading state
  isLoading: boolean = true;

  // Whether there are more pages to fetch
  hasMorePages: boolean = true;

  // first document in current page
  firstVisible: QueryDocumentSnapshot | unknown;

  // last document in current page
  lastVisible: QueryDocumentSnapshot | unknown;

  // Configuration for the store
  config: PageableCollectionStoreConfig<T>;

  constructor(config: PageableCollectionStoreConfig<T>) {
    this.config = config;
    makeObservable(this, {
      entities: observable,
      isLoading: observable,
      hasMorePages: observable,
      firstVisible: observable,
      lastVisible: observable,
      config: observable,
      resetState: action,
      setEntities: action,
      setIsLoading: action,
      setHasMorePages: action,
    });
  }

  resetState() {
    this.entities = [];
    this.isLoading = true;
    this.hasMorePages = true;
    this.firstVisible = null;
    this.lastVisible = null;
  }

  setEntities(entities: T[]) {
    this.entities = entities;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setHasMorePages(hasMorePages: boolean) {
    this.hasMorePages = hasMorePages;
  }

  protected async fetchEntities(db: Firestore,
                                direction: PaginationDirection,
                                searchQuery?: Query<DocumentData, DocumentData> | null) {
    this.setIsLoading(true);
    let startQuery;

    // reset to first page
    if (direction === 'none') {
      this.lastVisible = null;
      this.firstVisible = null;
    }

    if (direction === 'forward' && this.lastVisible) {
      startQuery = startAfter(this.lastVisible);
    } else if (direction === 'backward' && this.firstVisible) {
      startQuery = endBefore(this.firstVisible);
    }

    const endQueryConstraint = direction === 'backward'
      ? limitToLast(this.config.pageSize)
      : limit(this.config.pageSize);

    const queryConstraints = startQuery ? [startQuery, endQueryConstraint] : [endQueryConstraint];

    const baseQuery = query(
      collection(db, this.config.collectionPath),
      orderBy(this.config.orderByField, this.config.orderByDirection),
    );

    const documentQuery = searchQuery
      ? query(searchQuery as Query<DocumentData, DocumentData>, ...queryConstraints)
      : query(baseQuery, ...queryConstraints);

    const querySnapshot = await getDocs(documentQuery);

    // update firstVisible and lastVisible for the current page
    if (querySnapshot.docs.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.firstVisible = querySnapshot.docs[0];
      this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    }

    const result = querySnapshot.docs.map(this.config.convertDocToEntity);

    this.setHasMorePages(result.length === this.config.pageSize);
    this.setEntities(result);
    this.setIsLoading(false);
  };

}