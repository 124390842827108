import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import './Header.css';
import {
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import LogoutButton from '../Security/LogoutButton';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

const HeaderBar = styled(AppBar)({
  backgroundColor: 'rgb(0, 21, 41)',
  height: '64px', // Set the height of the header to a fixed value
});

interface HeaderProps {
  isAuthenticated: boolean;
}

const Header: React.FC<HeaderProps> = ({ isAuthenticated }) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(false);
  };

  const handleNavigation = (path: string) => {
    setIsDrawerOpen(false);
    navigate(path);
  };

  // List function doesn't need specific typing unless you're handling events or props
  const list = () => (
    <div
      role="presentation"
      onClick={handleDrawerClose}
      onKeyDown={handleDrawerClose}
    >
      <List>
        {isAuthenticated && (
          <ListItemButton onClick={() => handleNavigation('/')}>
            <ListItemText primary={t('home')} />
          </ListItemButton>
        )}
        {isAuthenticated && (
          <ListItemButton onClick={() => handleNavigation('/users')}>
            <ListItemText primary={t('users')} />
          </ListItemButton>
        )}
        {isAuthenticated && (
          <ListItemButton onClick={() => handleNavigation('/reports')}>
            <ListItemText primary={t('reports')} />
          </ListItemButton>
        )}
        {isAuthenticated && (
          <ListItemButton onClick={() => handleNavigation('/shops')}>
            <ListItemText primary={t('shops')} />
          </ListItemButton>
        )}
        {isAuthenticated && (
          <ListItemButton onClick={() => handleNavigation('/products')}>
            <ListItemText primary={t('products')} />
          </ListItemButton>
        )}
        {isAuthenticated && (
          <ListItemButton onClick={() => handleNavigation('/suppliers')}>
            <ListItemText primary={t('suppliers')} />
          </ListItemButton>
        )}
      </List>
    </div>
  );

  return (
    <>
      <HeaderBar position="sticky" className="palomind-header">
        <Toolbar>
          {/* Hide on larger screens */}
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            color="inherit"
            sx={{ textDecoration: 'none', marginRight: '24px' }}
            className="pm-logo"
          >
            Palomind
          </Typography>
          <Hidden mdDown>
            {isAuthenticated && (
              <Button
                color="inherit"
                component={Link}
                to="/"
                sx={{
                  marginRight: '16px',
                  textTransform: 'none',
                  fontSize: '0.9rem',
                }}
              >
                {t('home')}
              </Button>
            )}
            {isAuthenticated && (
              <Button
                color="inherit"
                component={Link}
                to="/users"
                sx={{ textTransform: 'none', fontSize: '0.9rem' }}
              >
                {t('users')}
              </Button>
            )}
            {isAuthenticated && (
              <Button
                color="inherit"
                component={Link}
                to="/reports"
                sx={{ textTransform: 'none', fontSize: '0.9rem' }}
              >
                {t('reports')}
              </Button>
            )}
            {isAuthenticated && (
              <Button
                color="inherit"
                component={Link}
                to="/shops"
                sx={{ textTransform: 'none', fontSize: '0.9rem' }}
              >
                {t('shops')}
              </Button>
            )}
            {isAuthenticated && (
              <Button
                color="inherit"
                component={Link}
                to="/products"
                sx={{ textTransform: 'none', fontSize: '0.9rem' }}
              >
                {t('products')}
              </Button>
            )}
            {isAuthenticated && (
              <Button
                color="inherit"
                component={Link}
                to="/suppliers"
                sx={{ textTransform: 'none', fontSize: '0.9rem' }}
              >
                {t('suppliers')}
              </Button>
            )}
          </Hidden>
          <Toolbar sx={{ flexGrow: 1 }} />
          <LanguageSelector />
          {isAuthenticated && <LogoutButton />}
        </Toolbar>
      </HeaderBar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
        {list()}
      </Drawer>
    </>
  );
};

export default Header;
