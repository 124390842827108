import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TableCell, TableRow } from '@mui/material';
import { User } from '../Types/Types';
import UserDrawer from '../Drawer/UserDrawer';
import TableActionsCell from '../../Common/Table/TableActionsButtons/TableActionsButtons';

interface UsersTableRowProps {
    user: User;
    onDelete: (userId: string) => Promise<void>;
    onSave: (user: User) => Promise<void>;
    onEdit: (user: User) => Promise<void>;
}

const UsersTableRow: React.FC<UsersTableRowProps> = observer(
  ({
    user, onSave, onEdit,
  }) => {
    const [isEditing] = useState(false);
    const [localUser, setLocalUser] = useState<User>(user);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [editInProgress] = useState(false);

    const handleEditDrawerOpen = async () => {
      await onEdit(localUser);
      setEditDrawerOpen(true);
    };

    const handleEditDrawerClose = () => {
      setEditDrawerOpen(false);
    };

    const handleEditDrawerSave = async (userData: User) => {
      setEditDrawerOpen(false);
      await onSave(userData);
      setLocalUser(userData);
    };

    return (
            <>
                <TableRow key={localUser.id} className={isEditing ? 'editing' : ''}>
                    <>
                        <TableCell>{localUser.email}</TableCell>
                        <TableCell>{localUser.name}</TableCell>
                        <TableCell>{localUser.shopId}</TableCell>
                        <TableCell>{localUser.shops?.join(', ')}</TableCell>
                        <TableCell>{localUser.reports?.join(', ')}</TableCell>
                        <TableActionsCell onEdit={handleEditDrawerOpen} />
                    </>
                </TableRow>
                <UserDrawer
                    isOpen={editDrawerOpen}
                    onClose={handleEditDrawerClose}
                    mode="edit"
                    loading={editInProgress}
                    initialUser={localUser}
                    onSave={handleEditDrawerSave}
                />
            </>
    );
  },
);

export default UsersTableRow;
