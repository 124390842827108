import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { AuthStateHook, useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { authInstance } from '../../services/firebase.service';

const LogoutButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user]: AuthStateHook = useAuthState(authInstance);

  const handleLogout = async () => {
    try {
      await authInstance.signOut();
      navigate('/login');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return user ? (
        <Button color="inherit" onClick={handleLogout} sx={{ textTransform: 'none' }} data-testid="logoutButton">
            {t('logout')}
        </Button>
  ) : null;
};

export default LogoutButton;
