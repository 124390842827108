import { getDatabase, ref, set } from 'firebase/database';
import { Product, ProductBasicInfo, ProductFullInfo } from '../Types/Types';

export default class ProductService {
  /**
     * Used for compatibility with older server infrastructure
     */
  public static async updateProductFullInfo(product: Product) {
    const fullProductInfo: ProductFullInfo = {
      barcode: product.barcode,
      weightCode: product.weightCode,
      name: product.name,
      sap: product.sap,
      supplier: product.supplier,
    };
    const db = getDatabase();
    await set(ref(db, `projects/101/products/full_info/${product.barcode}`), fullProductInfo);
  };

  /**
     * Used for compatibility with older server infrastructure
     */
  public static async updateProductBasicInfo (product: Product) {
    const basicProductInfo: ProductBasicInfo = {
      barcode: product.barcode,
      weightCode: product.weightCode,
      name: product.name,
    };
    const db = getDatabase();
    await set(ref(db, `projects/101/products/basic_info/${product.barcode}`), basicProductInfo);
  };

  /**
     * Used for compatibility with older server infrastructure
     */
  public static async updateProductBasicInfoBulk (products: Product[]) {
    const db = getDatabase();
    const productsRef = ref(db, 'projects/101/products/basic_info/');
    const productsMap: { [key: string]: Product } = {};

    products.forEach((product) => {
      productsMap[product.barcode] = product;
    });

    await set(productsRef, productsMap);
  };

  /**
     * Used for compatibility with older server infrastructure
     */
  public static async updateProductFullInfoBulk(products: Product[]) {
    const db = getDatabase();
    const productsRef = ref(db, 'projects/101/products/full_info/');
    const productsMap: { [key: string]: Product } = {};

    products.forEach((product) => {
      productsMap[product.barcode] = product;
    });

    await set(productsRef, productsMap);
  };

  public static async updateProductCacheMetadata (product: Product) {
    const db = getDatabase();
    await set(ref(db, `projects/101/products/cache_metadata/${product.barcode}`), {
      updatedAt: Date.now(),
    });
  };

  public static async updateProductCacheMetadataBulk() {
    const db = getDatabase();
    await set(ref(db, 'projects/101/products/cache_metadata/ALL'), {
      updatedAt: Date.now(),
    });
  };
}
