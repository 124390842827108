import { action, makeObservable, observable, toJS } from 'mobx';
import { collection, getFirestore, query, where } from 'firebase/firestore';
import * as Types from '../Types/Types';
import { PaginationDirection } from '../../Common/Types';
import { PageableCollectionStoreConfig } from '../../Common/Service/PageableCollectionStore';
import BasicPageableCollectionStore from '../../Common/Service/BasicPageableCollectionStore';

const suppliersPageableCollectionStoreConfig: PageableCollectionStoreConfig<Types.Supplier> = {
  collectionPath: 'projects/101/suppliers',
  orderByField: 'name',
  orderByDirection: 'asc',
  pageSize: 50,
  convertDocToEntity: (documentData) => ({
    ...documentData.data(),
    id: documentData.id,
  }),
};

class SuppliersTableStore extends BasicPageableCollectionStore<Types.Supplier> {

  suppliersSearchCriteria: Types.SupplierSearchCriteria = {};

  constructor() {
    super(suppliersPageableCollectionStoreConfig)
    makeObservable(this, {
      suppliersSearchCriteria: observable,
      saveSupplier: action,
      deleteSupplier: action,
      setSuppliersSearchCriteria: action,
    });
  }

  fetchSuppliers = async (direction: PaginationDirection) => {
    const db = getFirestore();
    const searchQuery = (criteria: Types.SupplierSearchCriteria) => {
      if (criteria.name) {
        return query(
          collection(db, suppliersPageableCollectionStoreConfig.collectionPath),
          where('name', '==', criteria.name),
        )
      }
      return null;
    }
    return super.fetchEntities(db, direction, searchQuery(this.suppliersSearchCriteria));
  };

  saveSupplier = async (supplier: Types.Supplier) => {
    await super.saveEntity(toJS(supplier));
  };

  async deleteSupplier(id: string) {
    await super.deleteEntity(id);
  }

  setSuppliersSearchCriteria = (
    suppliersSearchCriteria: Types.SupplierSearchCriteria,
  ) => {
    this.suppliersSearchCriteria = suppliersSearchCriteria;
  }
}

const suppliersTableStore = new SuppliersTableStore();
export default suppliersTableStore;