import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ProductsTable from '../Table/ProductsTable';
import ProductDrawer from '../Drawer/ProductDrawer';
import ProductSearch from '../Search/ProductSearch';
import ProductDrawerStore from '../Drawer/ProductDrawerStore';
import { Product, ProductSearchCriteria } from '../Types/Types';
import productsTableStore from '../Table/ProductsTableStore';
import { Supplier } from '../../Supplier/Types/Types';
import SupplierService from '../../Supplier/Service/SupplierService';

export const productDrawerStore = new ProductDrawerStore();

export const supplierOptionLabel = (option: Supplier) => {
  if (option.id === 'empty') {
    return '';
  }
  return `${option.name}`;
};

const ProductsDashboard: React.FC = observer(() => {
  const {
    drawerOpen,
    drawerMode,
    product,
    openCreateDrawer,
    closeDrawer,
    setShowValidation,
    isFormValid,
  } = productDrawerStore;
  const { saveProduct } = productsTableStore;
  const { t } = useTranslation();

  const [savingInProgress, setSavingInProgress] = useState(false);
  const [showSearchSection, setShowSearchSection] = useState(false);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  useEffect(() => {
    SupplierService.getSupplierList().then((result) => {
      // as backend don't support suppliers id's we only operate in name values
      const uniqueSuppliers = result.reduce((unique: Supplier[], item: Supplier) => {
        if (!unique.some((u: Supplier) => u.name === item.name)) {
          unique.push({ ...item, id: item.name });
        }
        return unique;
      }, [] as Supplier[]);
      setSuppliers(uniqueSuppliers);
    });
  }, []);

  const handleSave = async (productInfo: Product) => {
    setSavingInProgress(true);
    setShowValidation(true);
    if (isFormValid) {
      await saveProduct(productInfo);
      closeDrawer();
    }
    setSavingInProgress(false);
  };

  const handleOpenCreateDrawer = () => {
    setSavingInProgress(false);
    openCreateDrawer();
  };

  const toggleSearchSection = () => {
    if (showSearchSection) {
      productsTableStore.setProductsSearchCriteria({});
      productsTableStore.fetchProducts('none');
    }
    setShowSearchSection(!showSearchSection);
  };

  const handleSearch = (productSearchCriteria: ProductSearchCriteria) => {
    productsTableStore.setProductsSearchCriteria(productSearchCriteria);
    productsTableStore.fetchProducts('none');
  };

  return (
        <div>
            <Box sx={{ marginLeft: 1 }}>
                <h1>{t('products')}</h1>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button onClick={toggleSearchSection}>
                        {showSearchSection ? t('hideSearch') : t('showSearch')}
                    </Button>
                    <Button data-testid="addNewProductButton" onClick={handleOpenCreateDrawer}>{t('addNewProduct')}</Button>
                </Box>
            </Box>
            <Box sx={{ marginLeft: 1 }}>
                {showSearchSection && <ProductSearch onSearch={handleSearch} suppliers={suppliers}/>}
            </Box>
            <Box sx={{ marginTop: 1 }}>
                <ProductsTable suppliers={suppliers}/>
            </Box>

            <ProductDrawer
                isOpen={drawerOpen}
                onClose={closeDrawer}
                mode={drawerMode}
                initialProduct={product}
                onSave={handleSave}
                loading={savingInProgress}
                productDrawerStore={productDrawerStore}
                suppliers={suppliers}
            />
        </div>
  );
});

export default ProductsDashboard;
