import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://5626d9946830a27bde6c0fdbf32e475b@o4507414708748288.ingest.de.sentry.io/4507414711697488",
  integrations: [
    Sentry.browserTracingIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/palomind\.com/],
});
