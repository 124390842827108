import React, { useEffect } from 'react';
import {
  Box, Button, Drawer, FormHelperText, TextField,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Shop } from '../Types/Types';
import ShopDrawerStore from './ShopDrawerStore';
import LoadingButtonContent from '../../Common/Drawer/LoadingButtonContent/LoadingButtonContent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (shop: Shop) => void;
  mode: 'create' | 'edit';
  initialShop?: Shop | null; // only needed for edit
  loading: boolean;
  store: ShopDrawerStore;
}

const ShopDrawer: React.FC<Props> = observer(
  ({
     isOpen,
     onClose,
     onSave,
     mode,
     initialShop,
     loading,
     store,
   }) => {
    const {
      shop,
      setShop,
      resetShop,
      showValidation,
      isShopIdValid,
    } = store;
    const { t } = useTranslation();

    // Populate the form when in 'edit' mode
    useEffect(() => {
      if (mode === 'edit' && initialShop) {
        setShop(initialShop);
      } else {
        // Reset the form when in 'create' mode
        resetShop();
      }
    }, [mode, initialShop]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setShop({
        ...shop,
        [name]: value,
      });
    };

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      onSave(shop);
    };

    return (
      <Drawer anchor="right" open={isOpen} onClose={onClose}>
        <Box p={3} width={600}>
          <Typography variant="h6" gutterBottom>
            {mode === 'create' ? t('addShop') : t('editShop')}
          </Typography>
          <Box mt={2}>
            <form onSubmit={handleSubmit}>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="id"
                  label={t('id')}
                  value={shop.id}
                  data-testid="idInput"
                  disabled={mode === 'edit'}
                  onChange={handleChange}
                  error={showValidation && !isShopIdValid}
                />
                {showValidation && !isShopIdValid && (
                  <FormHelperText error data-testid="shopIdValidationError">{t('fieldIsRequired')}</FormHelperText>
                )}
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="city"
                  data-testid="cityInput"
                  label={t('city')}
                  value={shop.city}
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="name"
                  data-testid="nameInput"
                  label={t('name')}
                  value={shop.name}
                  onChange={handleChange}
                />
              </Box>
              <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  data-testid="shopSaveButton"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  <LoadingButtonContent loading={loading} mode={mode} />
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  data-testid="shopCancelButton"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('cancel')}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Drawer>
    );
  },
);

export default ShopDrawer;
