import React from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LoadingButtonContentProps {
  loading: boolean;
  mode: 'create' | 'edit';
}

const LoadingButtonContent: React.FC<LoadingButtonContentProps> = ({ loading, mode }) => {
  const { t } = useTranslation();

  if (loading) {
    return <CircularProgress size={24} />;
  }

  return <>{mode === 'create' ? t('add') : t('save')}</>;
};

export default LoadingButtonContent;