import { action, makeObservable, observable, toJS } from 'mobx';
import { collection, getFirestore, query, where } from 'firebase/firestore';
import { PaginationDirection } from '../../Common/Types';
import * as Types from '../Types/Types';
import { PageableCollectionStoreConfig } from '../../Common/Service/PageableCollectionStore';
import BasicPageableCollectionStore from '../../Common/Service/BasicPageableCollectionStore';

const shopsPageableCollectionStoreConfig: PageableCollectionStoreConfig<Types.Shop> = {
  collectionPath: 'projects/101/shops',
  orderByField: 'city',
  orderByDirection: 'asc',
  pageSize: 10,
  convertDocToEntity: (documentData) => ({
    ...documentData.data(),
    id: documentData.id,
  }),
};

class ShopsTableStore extends BasicPageableCollectionStore<Types.Shop> {

  shopsSearchCriteria: Types.ShopSearchCriteria = {};

  constructor() {
    super(shopsPageableCollectionStoreConfig)
    makeObservable(this, {
      shopsSearchCriteria: observable,
      saveShop: action,
      deleteShop: action,
    });
  }

  fetchShops = async (direction: PaginationDirection) => {
    const db = getFirestore();

    const searchQuery = (criteria: Types.ShopSearchCriteria) => {
      if (criteria.city) {
        return query(
          collection(db, 'projects/101/shops'),
          where('city', '==', criteria.city),
        );
      }
      return null;
    }

    return super.fetchEntities(db, direction, searchQuery(this.shopsSearchCriteria));
  };

  saveShop = async (shop: Types.Shop) => {
    await super.saveEntity(toJS(shop));
  };

  async deleteShop(id: string) {
    await super.deleteEntity(id);
  }
}

const shopsTableStore = new ShopsTableStore();
export default shopsTableStore;
