import {
  collection, getDocs, getFirestore, orderBy, query,
} from 'firebase/firestore';
import { Supplier } from '../Types/Types';

export default class SupplierService {
  public static async getSupplierList(): Promise<Supplier[]> {
    const db = getFirestore();
    const suppliersQuery = query(
      collection(db, 'projects/101/suppliers'),
      orderBy('name', 'asc'),
    );
    const querySnapshot = await getDocs(suppliersQuery);
    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  }
}
