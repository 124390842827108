import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, FormControl, Grid, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { supplierOptionLabel } from '../../Product/Dashboard/ProductsDashboard';
import { Supplier, SupplierSearchCriteria } from '../Types/Types';
import './SupplierSearch.css';

interface SupplierSearchProps {
  onSearch: (searchCriteria: SupplierSearchCriteria) => void;
  suppliers: Supplier[];
}

const SupplierSearch: React.FC<SupplierSearchProps> = (
  {
    onSearch,
    suppliers
  }) => {

  const { t } = useTranslation();
  const [searchCriteria, setSearchCriteria] = useState<SupplierSearchCriteria>({});
  const [supplierValue, setSupplierValue] = useState<Supplier | null>(null);

  const handleSearch = () => {
    onSearch(searchCriteria);
  };

  const handleSupplierChange = (supplier: Supplier | null) => {
    setSupplierValue(supplier);
    setSearchCriteria({
      ...searchCriteria,
      name: supplier?.name,
    });
  };

  return (
    <Grid container spacing={1} className="supplier-search">
      <Grid item xs={2}>
          <FormControl sx={{ width: '100%' }}>
            <Autocomplete
              options={suppliers}
              size="small"
              getOptionLabel={supplierOptionLabel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('supplier')}
                  variant="outlined"
                />
              )}
              value={supplierValue}
              noOptionsText={t('noOptions')}
              onChange={(event, value) => handleSupplierChange(value)} // Handle selected option
            />
          </FormControl>
        </Grid>
      <Grid item xs={2}>
        <Button variant="contained" endIcon={<SearchIcon />} onClick={handleSearch}>
          {t('search')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SupplierSearch;
