import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Product } from '../Types/Types';
import ProductsTableRow from './ProductsTableRow';
import productsTableStore from './ProductsTableStore';
import LoadMorePagination from '../../Common/LoadMorePagination';
import ProductDrawerStore from '../Drawer/ProductDrawerStore';
import { Supplier } from '../../Supplier/Types/Types';

export const tableProductDrawerStore = new ProductDrawerStore();


type ProductsTableBodyProps = {
  products: Product[];
  onSave: (product: Product) => Promise<void>;
  onEdit: (product: Product) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  suppliers: Supplier[];
};

const ProductsTableBody: React.FC<ProductsTableBodyProps> = observer(
  ({
     products,
     onSave,
     onEdit,
     onDelete,
     suppliers,
   }) => (
    <>
      {products.map((product) => (
        <ProductsTableRow
          key={product.barcode}
          product={product}
          onDelete={onDelete}
          onEdit={onEdit}
          onSave={onSave}
          suppliers={suppliers}
        />
      ))}
    </>
  ),
);

interface ProductsTableProps {
  suppliers: Supplier[];
}

const ProductsTable: React.FC<ProductsTableProps> = observer((
  {
    suppliers,
  },
) => {
  const { entities, isLoading, hasMorePages } = productsTableStore;
  const { setProduct } = tableProductDrawerStore;
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();

  useEffect(() => {
    productsTableStore.setProductsSearchCriteria({ searchBy: 'barcode' }); // reset search criteria
    productsTableStore.fetchProducts('forward');

    return () => {
      // Reset the state here
      productsTableStore.resetState();
    };
  }, []);

  const handleDelete = async (id: string) => {
    await productsTableStore.deleteProduct(id);
  };

  const handleSave = async (product: Product) => {
    await productsTableStore.saveProduct(product);
  };

  const handleEdit = async (product: Product) => {
    setProduct(product);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <>
      <Table data-testid="productsTable">
        <TableHead>
          <TableRow style={{ backgroundColor: '#f4f4f4' }}>
            <TableCell style={{ fontWeight: 'bold' }}>{t('barcode')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('name')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('sap')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('supplier')}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('weightCode')}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ProductsTableBody
            products={entities}
            onDelete={handleDelete}
            onSave={handleSave}
            onEdit={handleEdit}
            suppliers={suppliers}
          />
        </TableBody>
      </Table>
      <LoadMorePagination
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        hasMorePages={hasMorePages}
        isLoading={isLoading}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        fetchProducts={productsTableStore.fetchProducts}
      />
    </>
  );
});


export default ProductsTable;
