import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LanguageIcon from '@mui/icons-material/Language';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  // Set the initial locale of dayjs to match the current language of i18n
  dayjs.locale(i18n.language);

  const handleLanguageChange = async (event: SelectChangeEvent<string>) => {
    try {
      await i18n.changeLanguage(event.target.value);
      dayjs.locale(event.target.value);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Select
      value={i18n.language}
      onChange={handleLanguageChange}
      IconComponent={LanguageIcon}
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        '& .MuiSvgIcon-root': {
          color: 'white',
          fontSize: '1rem',
        },
        '&:before': { borderColor: 'transparent' },
        '&:after': { borderColor: 'transparent' },
        '&:hover': { borderColor: 'transparent' },
        border: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      }}
    >
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="lt">Lietuvių</MenuItem>
    </Select>
  );
};

export default LanguageSelector;
