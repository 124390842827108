import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadMorePagination from '../../Common/LoadMorePagination';
import shopsTableStore from './ShopsTableStore';
import { Shop } from '../Types/Types';
import ShopsTableRow from './ShopsTableRow';
import ShopDrawerStore from '../Drawer/ShopDrawerStore';

export const shopTableDrawerStore = new ShopDrawerStore();

const ShopsTable: React.FC = observer(() => {
  const { entities, isLoading, hasMorePages } = shopsTableStore;
  const { setShop } = shopTableDrawerStore;
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();

  useEffect(() => {
    shopsTableStore.fetchShops('forward');

    return () => {
      // Reset the state here
      shopsTableStore.resetState();
    };
  }, []);

  const handleDelete = async (id: string) => {
    await shopsTableStore.deleteShop(id);
  };

  const handleSave = async (shop: Shop) => {
    await shopsTableStore.saveShop(shop);
  };

  const handleEdit = async (shop: Shop) => {
    setShop(shop);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
        <>
            <Table data-testid="shopsTable">
                <TableHead>
                    <TableRow style={{ backgroundColor: '#f4f4f4' }}>
                        <TableCell style={{ fontWeight: 'bold' }}>{t('id')}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>{t('city')}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>{t('name')}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entities.map((shop) => (
                        <ShopsTableRow
                            key={shop.id}
                            shop={shop}
                            onDelete={handleDelete}
                            onEdit={handleEdit}
                            onSave={handleSave}
                        />
                    ))}
                </TableBody>
            </Table>
            <LoadMorePagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                hasMorePages={hasMorePages}
                isLoading={isLoading}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                fetchProducts={shopsTableStore.fetchShops}
            />
        </>
  );
});

export default ShopsTable;
