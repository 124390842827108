import {
  action, computed, makeObservable, observable,
} from 'mobx';
import * as Types from '../Types/Types';

export default class ShopDrawerStore {
  drawerOpen: boolean = false;

  drawerMode: 'create' | 'edit' = 'create';

  shop: Types.Shop = {
    id: '',
    city: '',
    name: '',
  };

  showValidation: boolean = false;

  constructor() {
    makeObservable(this, {
      drawerOpen: observable,
      drawerMode: observable,
      shop: observable,
      showValidation: observable,
      openCreateDrawer: action,
      openEditDrawer: action,
      closeDrawer: action,
      isShopIdValid: computed,
      setShowValidation: action,
      isValidationVisible: computed,
      isFormValid: computed,
      setShop: action,
      resetShop: action,
    });
  }

  setShop = (shop: Types.Shop) => {
    this.shop.id = shop.id;
    this.shop.city = shop.city;
    this.shop.name = shop.name;
  };

  resetShop = () => {
    this.shop.id = '';
    this.shop.city = '';
    this.shop.name = '';
  };

  // Drawer related actions
  openCreateDrawer = () => {
    this.drawerMode = 'create';
    this.drawerOpen = true;
    this.showValidation = false;
    this.resetShop();
  };

  openEditDrawer = (shop: Types.Shop) => {
    this.setShop(shop);
    this.drawerMode = 'edit';
    this.drawerOpen = true;
  };

  closeDrawer = () => {
    this.drawerOpen = false;
    this.resetShop();
    this.showValidation = false;
  };

  setShowValidation = (showValidation: boolean) => {
    this.showValidation = showValidation;
  };

  get isValidationVisible() {
    return this.showValidation;
  }

  get isFormValid() {
    return this.isShopIdValid;
  }

  get isShopIdValid() {
    return (this.shop?.id?.length ?? 0) >= 1;
  }
}
