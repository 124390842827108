import React from 'react';
import { TablePagination, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PaginationDirection } from './Types';

interface ServerSideTablePaginationProps {
  currentPage: number;
  rowsPerPage: number;
  hasMorePages: boolean;
  isLoading: boolean;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fetchProducts: (direction: PaginationDirection) => void;
}

const LoadMorePagination: React.FC<ServerSideTablePaginationProps> = ({
  currentPage,
  rowsPerPage,
  hasMorePages,
  isLoading,
  handleChangePage,
  handleChangeRowsPerPage,
  fetchProducts,
}) => {
  const { t } = useTranslation();
  const handleChangePageLocal = (
    newPage: number,
    direction: PaginationDirection,
  ) => {
    // onSave(localProduct);
    handleChangePage(null, newPage);
    fetchProducts(direction);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[]}
      labelDisplayedRows={() => ''}
      labelRowsPerPage=""
      component="div"
      count={-1} // Indeterminate number of items
      rowsPerPage={rowsPerPage}
      page={currentPage}
      onPageChange={() => handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={() => (
        <>
          {isLoading && <CircularProgress />}
          {!isLoading && !hasMorePages && currentPage === 0 ? (
            <Button
              disabled={isLoading}
              onClick={() => handleChangePageLocal(0, 'none')}
            >
              {t('refresh')}
            </Button>
          ) : (
            <>
              <Button
                disabled={currentPage === 0 || isLoading}
                onClick={() => handleChangePageLocal(currentPage - 1, 'backward')
                }
              >
                {t('previous')}
              </Button>
              <Button
                disabled={!hasMorePages || isLoading}
                onClick={() => handleChangePageLocal(currentPage + 1, 'forward')
                }
              >
                {t('next')}
              </Button>
            </>
          )}
        </>
      )}
    />
  );
};

export default LoadMorePagination;
