import React, { useEffect, useState } from 'react';
import {
  Alert, Box, Button, CircularProgress,
} from '@mui/material';
import * as XLSX from 'xlsx';
import {
  doc, getFirestore, Timestamp, writeBatch,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Product } from '../Types/Types';
import ProductService from '../Service/ProductService';
import productSupplementStore from '../Supplement/ProductSupplementStore';

const ProductImport: React.FC = observer(() => {
  const {
    loading,
    supplements,
    resetState,
    fetchProductSupplements,
  } = productSupplementStore;

  useEffect(() => {
    fetchProductSupplements();
    return () => {
      resetState();
    };
  }, []);
  const { t } = useTranslation();
  const db = getFirestore();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const parseWeightCode = (barcode: string | undefined): string | undefined => (barcode ? barcode.substring(3, barcode.length - 6) : undefined);

  const findSupplement = (sap: string | undefined): string | undefined | null => {
    if (sap) {
      const supplement = supplements.find((s) => s.ownerProductCd === sap);
      return supplement ? supplement.supplementProductCd : null;
    }
    return null;
  };

  const createProduct = (row: unknown[]): Product => {
    let kgUnit = false;
    let weightCode: string | null;

    // Replace these indices with the actual indices of your Excel columns
    const SAP_IDX = 0; // Index of the SAP column
    const UNIT_TYPE_IDX = 1; // Index of the Matavimo vienetas column
    const NAME_IDX = 2; // Index of the Pavadinimas column
    const SUPPLIER_IDX = 4; // Index of the Tiekėjas column
    const BARCODE_IDX = 5; // Index of the EAN/UPC column

    // Check if the unit type is 'kg'
    if (row[UNIT_TYPE_IDX]?.toString().toLowerCase() === 'kg') {
      kgUnit = true;
    }

    // Get the barcode and parse the weight code if kgUnit is true
    const barcode = row[BARCODE_IDX]?.toString();
    if (kgUnit) {
      weightCode = parseWeightCode(barcode) ?? null;
    } else {
      weightCode = null;
    }

    return {
      barcode: barcode!, // Assuming 'barcode' variable is already handled appropriately
      name: row[NAME_IDX]?.toString() ?? '',
      sap: row[SAP_IDX]?.toString() ?? '',
      supplier: row[SUPPLIER_IDX]?.toString() ?? '',
      weightCode: weightCode ?? null,
      returnablePackageCd: findSupplement(row[SAP_IDX]?.toString() ?? undefined) ?? '',
      updatedAt: Timestamp.now(),
    };
  };

  const saveProductsInBatches = async (
    products: Product[],
    setLoading: (loadingProducts: boolean) => void,
  ) => {
    setLoading(true); // Set loading to true at the start
    setError(''); // Clear any previous errors
    setSuccess('');

    const BATCH_SIZE = 500;

    // Split products into batches
    const productBatches = [];
    for (let i = 0; i < products.length; i += BATCH_SIZE) {
      productBatches.push(products.slice(i, i + BATCH_SIZE));
    }

    try {
      // Process each batch concurrently
      await Promise.all(productBatches.map(async (productBatch) => {
        const batch = writeBatch(db);

        productBatch.forEach((product) => {
          if (product.barcode) {
            const docRef = doc(db, 'projects/101/products', product.barcode);
            batch.set(docRef, product);
          }
        });

        await batch.commit();
      }));

      // backward compatibility
      await ProductService.updateProductBasicInfoBulk(products);
      await ProductService.updateProductFullInfoBulk(products);
      await ProductService.updateProductCacheMetadataBulk();

      setSuccess('Products have been successfully imported.');
    } catch (err) {
      setError('Failed to import products. Please try again.'); // Set a user-friendly error message
      // console.error('Error importing products:', err);
    } finally {
      setLoading(false); // Reset loading to false once done or if an error occurs
    }
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const binaryStr = e.target?.result;
      if (typeof binaryStr !== 'string') return;

      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      const products: Product[] = [];
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const data: unknown[][] = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        }); // Get data as a 2D array
        data.forEach((row, index) => {
          if (index !== 0) {
            // Skip header row
            products.push(createProduct(row));
          }
        });
      });
      saveProductsInBatches(products, setIsLoading);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div>
      {success && <Alert severity="success">{t('productsImported')}</Alert>}
      {error && <Alert severity="error">{t('productsImportFailed')}</Alert>}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: '80vh' }}
      >
        <input
          accept=".xlsx, .xls"
          id="contained-button-file"
          multiple
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            disabled={loading || isLoading}
          >
            {(loading || isLoading) && <CircularProgress />}
            {t('uploadFile')}
          </Button>
        </label>
      </Box>
    </div>
  );
});

export default ProductImport;
