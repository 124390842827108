import {
  action, computed, makeObservable, observable,
} from 'mobx';
import * as Types from '../Types/Types';

export default class SupplierDrawerStore {
  drawerOpen: boolean = false;

  drawerMode: 'create' | 'edit' = 'create';

  supplier: Types.Supplier = {
    id: '',
    name: '',
  };

  showValidation: boolean = false;

  constructor() {
    makeObservable(this, {
      drawerOpen: observable,
      drawerMode: observable,
      supplier: observable,
      showValidation: observable,
      openCreateDrawer: action,
      openEditDrawer: action,
      closeDrawer: action,
      isSupplierNameValid: computed,
      setShowValidation: action,
      isValidationVisible: computed,
      isFormValid: computed,
      setSupplier: action,
      resetSupplier: action,
    });
  }

  setSupplier = (supplier: Types.Supplier) => {
    this.supplier.id = supplier.id;
    this.supplier.name = supplier.name;
  };

  resetSupplier = () => {
    this.supplier.id = '';
    this.supplier.name = '';
  };

  // Drawer related actions
  openCreateDrawer = () => {
    this.drawerMode = 'create';
    this.drawerOpen = true;
    this.showValidation = false;
    this.resetSupplier();
  };

  openEditDrawer = (supplier: Types.Supplier) => {
    this.setSupplier(supplier);
    this.drawerMode = 'edit';
    this.drawerOpen = true;
  };

  closeDrawer = () => {
    this.drawerOpen = false;
    this.resetSupplier();
    this.showValidation = false;
  };

  setShowValidation = (showValidation: boolean) => {
    this.showValidation = showValidation;
  };

  get isValidationVisible() {
    return this.showValidation;
  }

  get isFormValid() {
    return this.isSupplierNameValid;
  }

  get isSupplierNameValid() {
    return (this.supplier?.name?.length ?? 0) >= 1;
  }
}
