import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query, QueryFieldFilterConstraint, QueryLimitConstraint, QueryOrderByConstraint,
  where,
} from 'firebase/firestore';
import { DocumentData } from '@firebase/firestore';
import { action, makeObservable, observable } from 'mobx';
import * as Types from '../Types/Types';
import { CompletedReport } from '../Types/Types';

class CompletedReportsTableStore {
  criteria: Types.CompletedReportSearchCriteria = {};

  loading: boolean = false;

  completedReportsPath = 'projects/101/reports/palo_scan/completed';

  completedReports: CompletedReport[] = [];

  showMaxReportCountWarning: boolean = false;

  maxReportCount: number = 1000;

  constructor() {
    makeObservable(this, {
      criteria: observable,
      loading: observable,
      completedReports: observable,
      showMaxReportCountWarning: observable,
      setCompletedReports: action,
      setLoading: action,
      setMaxReportCountWarning: action,
      setCriteria: action,
      fetchReports: action,
    });
  }

  setMaxReportCountWarning = (showMaxReportCountWarning: boolean) => {
    this.showMaxReportCountWarning = showMaxReportCountWarning;
  };

  setCriteria = (criteria: Types.CompletedReportSearchCriteria) => {
    this.criteria = criteria;
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setCompletedReports = (completedReports: CompletedReport[]) => {
    this.completedReports = completedReports;
  };

  // eslint-disable-next-line class-methods-use-this
  convertReportDocToEntity = (documentData: DocumentData): CompletedReport => ({
    ...documentData.data(),
    id: documentData.id,
  });

  private buildConditions = ():  (QueryOrderByConstraint | QueryLimitConstraint | QueryFieldFilterConstraint)[] => {
    const conditions: (QueryOrderByConstraint | QueryLimitConstraint | QueryFieldFilterConstraint)[] = []
    conditions.push(orderBy('reportDate', 'desc'));
    if (!this.isCriteriaEmpty()) {
      conditions.push(limit(this.maxReportCount));
    } else {
      conditions.push(limit(10));
    }
    if (this.criteria.reportDateAfter) {
      conditions.push(where('reportDate', '>=', this.criteria.reportDateAfter));
    }
    if (this.criteria.reportDateBefore) {
      conditions.push(where('reportDate', '<=', this.criteria.reportDateBefore));
    }
    if (this.criteria.shopId) {
      conditions.push(where('shopId', '==', this.criteria.shopId));
    }
    if (this.criteria.supplier) {
      conditions.push(where('supplier', '==', this.criteria.supplier));
    }
    return conditions;
  }

  // eslint-disable-next-line consistent-return
  fetchReports = async () => {
    let reports: Types.CompletedReport[] = []
    this.setLoading(true);
    const db = getFirestore();
    const baseQuery = collection(db, this.completedReportsPath);
    this.setCompletedReports([]);
    this.setMaxReportCountWarning(false);

    if (this.criteria.reportTypes && this.criteria.reportTypes.length > 0) {
      const reportsPromises = this.criteria.reportTypes.map(async (reportType) => {
        const conditions = this.buildConditions();
        conditions.push(where('category', '==', reportType))
        const reportsQuery = query(baseQuery, ...conditions);
        const querySnapshot = await getDocs(reportsQuery);
        return querySnapshot.docs.map(this.convertReportDocToEntity);
      });
      const reportsArrays = await Promise.all(reportsPromises);
      reports = reportsArrays.flat();
    } else if (!this.isCriteriaEmpty()) {
      const conditions = this.buildConditions();
      const reportsQuery = query(baseQuery, ...conditions);
      const querySnapshot = await getDocs(reportsQuery);
      reports = querySnapshot.docs.map(this.convertReportDocToEntity);
    } else {
      const reportsQuery = query(
        collection(db, this.completedReportsPath),
        orderBy('reportDate', 'desc'),
        limit(10),
      );
      const querySnapshot = await getDocs(reportsQuery);
      reports = querySnapshot.docs.map(this.convertReportDocToEntity);
    }

    if (reports.length >= this.maxReportCount) {
      this.setMaxReportCountWarning(true);
    }
    this.setCompletedReports(reports);
    this.setLoading(false);
  };

  resetState = () => {
    this.setLoading(false);
    this.setCompletedReports([]);
    this.setCriteria({});
    this.setLoading(false);
    this.setMaxReportCountWarning(false);
  };

  isCriteriaEmpty = () => (
    (!this.criteria.reportTypes || this.criteria.reportTypes.length === 0) &&
      !this.criteria.reportDateAfter &&
      !this.criteria.reportDateBefore &&
      !this.criteria.shopId &&
      !this.criteria.supplier
    )
}

const completedReportsTableStore = new CompletedReportsTableStore();
export default completedReportsTableStore;
