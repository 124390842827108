import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  TableCell, TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Shop } from '../Types/Types';
import ConfirmDialog from '../../Common/ConfirmDialog';
import ShopDrawer from '../Drawer/ShopDrawer';
import TableActionsCell from '../../Common/Table/TableActionsButtons/TableActionsButtons';
import { shopTableDrawerStore } from './ShopsTable';

interface ShopsTableRowProps {
  shop: Shop;
  onDelete: (shopId: string) => Promise<void>;
  onSave: (shop: Shop) => Promise<void>;
  onEdit: (shop: Shop) => Promise<void>;
}

const ShopsTableRow: React.FC<ShopsTableRowProps> = observer(
  ({
    shop, onDelete, onSave, onEdit,
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localShop, setLocalShop] = useState<Shop>(shop);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [editInProgress] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      setLocalShop(shop);
    }, [shop]);

    const handleEditDrawerOpen = async () => {
      await onEdit(localShop);
      setEditDrawerOpen(true);
    };

    const handleClose = () => {
      setDialogOpen(false);
    };

    const handleEditDrawerClose = () => {
      setEditDrawerOpen(false);
    };

    const handleEditDrawerSave = async (updatedShop: Shop) => {
      setEditDrawerOpen(false);
      await onSave(updatedShop);
    };

    const handleConfirm = async () => {
      await onDelete(localShop.id);
      setIsEditing(false);
      setDialogOpen(false);
    };

    return (
      <>
        <TableRow data-testid="shopsTableDataRow"
          key={localShop.id} className={isEditing ? 'editing' : ''}>
          <>
            <TableCell>{localShop.id}</TableCell>
            <TableCell>{localShop.city}</TableCell>
            <TableCell>{localShop.name}</TableCell>
            <TableActionsCell
              onEdit={handleEditDrawerOpen}
              onDelete={() => setDialogOpen(true)}
            />
          </>
        </TableRow>
        <ConfirmDialog
          open={dialogOpen}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          title={t('shopDeleteConfirmationTitle')}
          description={t('shopDeleteConfirmation')}
        />
        <ShopDrawer
          isOpen={editDrawerOpen}
          onClose={handleEditDrawerClose}
          mode="edit"
          loading={editInProgress}
          initialShop={localShop}
          onSave={handleEditDrawerSave}
          store={shopTableDrawerStore}
        />
      </>
    );
  },
);

export default ShopsTableRow;
